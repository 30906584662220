
define('include/mono', [
    'marionette',
    'backbone.async.events',
    'marionette.slideview',
    'marionette.gridview',
    'marionette.cycleview',
    'marionette.swipeview',
    'marionette.fitview',
    'marionette.ext',
    'jquery.velocity',
    'jquery.plugins',
    'jquery.idle-timer',
    'jquery.ajax-progress',
    'jquery.photoset-grid',
    'jquery.flowtype',
    'jquery.fit',
    'jquery.fitvids',
    'jquery.fittext',
    'jquery.resizetruncate',
    'jquery.columnizer',
    'bootstrap/transition', 
    'bootstrap/collapse', 
    'bootstrap/dropdown'
]);
