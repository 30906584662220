
(function (factory) {
    if (typeof define === 'function' && define.amd) {
        define('jquery.plugins',['jquery'], factory);
    } else {
        factory(jQuery);
    }
}(function($) {
    
    var urlRegexp = /(.*?)\/([^\/\.]+)\.([a-z]+)$/;
    var tinyGIF = 'data:image/gif;base64,' + 'R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs=';
    var animMethod = _.isFunction($.fn.velocity) ? 'velocity' : 'animate';
    
    $.event.special['transition:end'] = {
        _events: 'transitionend webkitTransitionEnd oTransitionEnd otransitionend',
        setup: function(data, namespaces) {
            var self = this;
            $(this).on($.event.special['transition:end']._events, function(event) {
                $.event.special['transition:end'].handler.call(self, event);
            });
        },
        teardown: function(namespaces){
            $(this).off($.event.special['transition:end']._events);
        },
        handler: function(event) {
            event.type = 'transition:end';
            $.event.dispatch.call(this, event);
        }
    };
    
    $.sync = $.sync || function sync(functions, options) {
        options = options || {};
        var deferred = $.Deferred();
        var lastDeferred = deferred;
        if (_.isFunction(options.before)) {
            deferred.then(options.before);
        }
        _.each(functions, function(fn) {
            if (_.isFunction(fn)) lastDeferred = lastDeferred.then(fn);
        });
        if (_.isFunction(options.after)) lastDeferred.then(options.after);
        return options.resolve !== false ? deferred.resolve() : deferred;
    };
    
    $.async = $.async || function async(functions, options) {
        options = options || {};
        
        var fns = _.compact(_.map(functions, function(fn) {
            if (_.isFunction(fn)) return fn();
        }));
        
        var deferred = $.Deferred();
        if (_.isFunction(options.before)) deferred.then(options.before);
        deferred.then(function() { return $.when.apply($, fns); });
        if (_.isFunction(options.after)) deferred.then(options.after);
        return options.resolve !== false ? deferred.resolve() : deferred;
    };
    
    $.fn.quickFade = function(bool, animate) {
        var method = bool ? 'show' : 'hide';
        if (animate) {
            var speed = _.isNumber(animate) ? animate : 'slow';
            var anim = bool ? { opacity: 1 } : { opacity: 0 };
            $(this).stop().show()[animMethod](anim, speed || 'slow', function() {
                $(this)[method]();
            });
        } else {
            $(this)[method]();
        }
    };
    
    $.fn.randomize = function(selector) {
        (selector ? this.find(selector) : this).parent().each(function(){
            $(this).children(selector).sort(function(){
                return Math.random() - 0.5;
            }).detach().appendTo(this);
        });
        return this;
    };
    
    $.fn.splitBy = function(limit, wrapper) {
        $all = $(this).find('>*');
        var fragment = Math.ceil($all.length/limit);  
        for (i = 0; i < fragment; i++) {
            var wrap = $.isFunction(wrapper) ? wrapper(i) : wrapper;
            $all.slice(limit * i, limit * (i + 1)).wrapAll(wrap);
        }
        return $(this);
    };
    
    $.fn.splitUp = function(columns, wrapper) {
        $all = $(this).find('>*');
        var fragment = Math.ceil($all.length/columns);
        for (i = 0; i < columns; i++) {
            var wrap = $.isFunction(wrapper) ? wrapper(i) : wrapper;
            $all.slice(fragment * i, fragment * (i + 1)).wrapAll(wrap);
        }
        return $(this);
    };
    
    $.fn.makeColumns = function(columnHtml) {
        columnHtml = columnHtml || '<div class="col-sm-6"></div>';
        $(this).each(function() {
            var $content = $(this);
            if (!$content.is('.multicolumn')) {
                $content.addClass('multicolumn');
                var $para = $content.find('p');
                if ($para.length === 1) return;
                var length = $content.text().length;
                var size = Math.ceil(length/2);
                size = size + Math.ceil(size/8);
                var total = 0;
                var columnA = $(columnHtml);
                var columnB = $(columnHtml);
                var column = columnA;
                var contents = $content.contents();
                _.each(contents, function(node) {
                    total += $(node).text().length;
                    if (node.tagName && node.tagName.match(/^H\d/)) {
                        var next = $(node).next('p').eq(0).text().length;
                        if (total + next > size) column = columnB;
                    }
                    if (total > size) column = columnB;
                    column.append(node);
                });
                $content.empty();
                $content.append(columnA);
                $content.append(columnB);
            }
        });
        return this;
    };
    
    $.fn.breakColumns = function(columnHtml, fallbackHtml) {
        columnHtml = columnHtml || '<div class="col-sm-6"></div>';
        $(this).each(function() {
            var $content = $(this);
            if (!$content.is('.multicolumn')) {
                $content.addClass('multicolumn');
                if ($content.find('hr').is('*')) {
                    var columnA = $(columnHtml);
                    var columnB = $(columnHtml);
                    var column = columnA;
                    var contents = $content.contents();
                    $content.empty();
                    _.each(contents, function(node) {
                        if (node.tagName === 'HR') {
                            column = columnB;
                        } else {
                            column.append(node);
                        }
                    });
                    $content.append(columnA);
                    $content.append(columnB);
                } else {
                    $content.wrapInner(fallbackHtml || columnHtml);
                }
            }
        });
        return this;
    };
    
    $.fn.isSmallScreen = function() {
        var width = $(this).width(), height = $(this).height();
        var landscape = width > height;
        return (landscape && width < 992) || (!landscape && height < 768);
    };

    $.fn.throttledResize = function(callback, interval) {
        $(this).on('resize', _ && _.throttle ? _.throttle(callback, interval || 100) : callback);
        return this;
    };
    
    $.fn.debouncedResize = function(callback, interval) {
        $(this).on('resize', _ && _.debounce ? _.debounce(callback, interval || 100) : callback);
        return this;
    };
    
    var csstransforms  = Modernizr && Modernizr.csstransforms;
    var csstransitions = Modernizr && Modernizr.csstransitions;
    
    function getMatrix(el) {
        var css = $(el).css(Modernizr.prefixed('transform'));
        var ctm = css.replace( /[a-z()]/gi, '' ).split( ',' );
        if (ctm.length < 6) { return [ 1, 0, 0, 1, 0, 0 ]; }
        for (var i = 0; i < 6; i++) { ctm[i] = parseFloat(ctm[i]); }
        return ctm;
    };

    var absolutePosition = function(animate, transform, element) {
        var $elem = $(element), $parent = $elem.parent();
        var parentPosition = $parent.css('position');
        if (parentPosition === 'static') $parent.css('position', 'relative');
        var elemPosition = $elem.css('position');
        if (elemPosition !== 'absolute') {
            $elem.css({ 
                position: 'absolute',
                top: 0, left: 0,
                float: 'none', minWidth: 'auto', minHeight: 'auto' 
            });
        }
        
        if (csstransforms) {
            var matrix = getMatrix(element);
            var x = matrix[4] + transform.tx;
            var y = matrix[5] + transform.ty;
            if (animate && $.isFunction($.fn.velocity)) {
                var options = { queue: false, easing: 'snap', duration: 400 };
                $elem.velocity({
                    translateX: x, translateY: y,
                    width: transform.width, height: transform.height
                }, options);
            } else {
                $elem.width(transform.width).height(transform.height);
                $elem.css(Modernizr.prefixed('transform'), 'translate(' + x + 'px,' + y + 'px)');
            }
        } else {
            var position = $elem.position();
            var left = (position.left + (isNaN(transform.tx) ? 0 : transform.tx)) + 'px';
            var top = (position.top + (isNaN(transform.ty) ? 0 : transform.ty)) + 'px';
            if (animate) {
                var method = $.isFunction($.fn.velocity) ? 'velocity' : 'animate';
                $elem.stop()[method]({ 
                    top: top, left: left, 
                    width: transform.width + 'px', height: transform.height + 'px' 
                }, { queue: false });
            } else {
                $elem.width(transform.width).height(transform.height);
                $elem.css({ top: top, left: left });
            }
        }
    };
    
    $.fn.fit = function(elem, options, callback) {
        if (elem === 'on' || elem === 'off' || elem === 'trigger') {
            this.each(function() {
                if (typeof($(this).data('fit')) === 'object') {
                    $(this).data('fit')[elem]();
                }
            });
            return this;
        }
        
        if ($.isFunction(options)) callback = options, options = {};
        options = $.extend({}, options);
        var container = $(this)[0];
        var elem = $(elem)[0];
        
        callback = callback || $.proxy(absolutePosition, null, options.animate);
        
        var transform = fit(elem, container, options, callback);
        
        if (options.watch) {
            $(this).data('fit', transform);
            $(this).on('fit.on', $.proxy(transform.on, transform, null));
            $(this).on('fit.off', $.proxy(transform.off, transform, null));
            $(this).on('fit.trigger', $.proxy(transform.trigger, transform, null));
        }
        
        return this;
    };
    
    $.fn.swap = function(b) {
        b = this.parent().children(b);
        b.insertAfter(this[0]);
        return this;
    };
    
    $.fn.setImageSrc = function(src, callback) {
        if ($.isFunction(src)) callback = src, src = null;
        var elem = $(this);
        var baseSrc = elem.data('src') || elem.attr('src') || src;
        elem.data('src', baseSrc); // keep original
        if ($.isFunction(callback)) {
            var matches = baseSrc.match(urlRegexp);
            if (matches) { // path, filename, ext
                src = callback(matches[1], matches[2], matches[3]);
            }
        }
        src = src || baseSrc || tinyGIF; // pass nothing to reset
        if (elem.is('img')) {
            if (elem.attr('src') !== src) {
                elem.attr('src', src);
                elem.one('error', function() {
                    $(this).attr('src', tinyGIF);
                });
            }
        } else {
            this.addClass('bg-img');
            this.css('background-image', 'url("' + src + '")');
        }        
        return this;
    };
    
    // $('div').cover({ watch: true });
    // $('div').trigger('fit.off');
    // $('div').trigger('fit.on');
    // $('div').cover('off');
    // $('div').cover('on');
    
    $.fn.cover = function(elem, options) {
        if (elem === 'on' || elem === 'off' || elem === 'trigger') {
            return this.fit(elem);
        }
        
        var args = arguments;
        this.each(function() {
            var $this = $(this);
            if (elem instanceof HTMLElement || typeof(elem) === 'string') {
                var $elem = $this.children(elem).eq(0);
            } else if (elem instanceof jQuery) {
                var $elem = elem.eq(0);
            } else if (typeof(elem) === 'object' || args.length === 0) {
                var $elem = $this.children('img').eq(0);
                options = typeof(elem) === 'object' ? elem : {};
            }
            
            var defaults = { cover: true, hAlign: 'center', vAlign: 'center' };
            defaults.hAlign = $this.data('h-align') || defaults.hAlign;
            defaults.vAlign = $this.data('v-align') || defaults.vAlign;
                        
            options = $.extend(defaults, options || {});
            
            if (options.background && options.cover 
                && Modernizr && Modernizr.backgroundsize && $elem.is('img')) {
                $this.addClass('cover-bg');
                $this.css('background-image', 'url("' + $elem.attr('src') + '")');
                $this.css('background-position', options.hAlign + ' ' + options.vAlign);
                $this.css('background-size', 'cover');
                $elem.remove();
            } else if ($elem.is('*')) {
                if ($elem.is('img') && !$elem.hasClass('cover')) {
                    $elem.one('load', function() {
                        $this.fit($elem, options);
                    });
                }
                $this.fit($elem, options);
                $elem.addClass('cover');
            }
        });
        
        return this;
    };
        
}));