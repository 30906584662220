
(function (factory) {
    if (typeof define === 'function' && define.amd) {
        define('marionette.fitview',['jquery', 'underscore', 'marionette', 'fit', 'marionette.slideview'], factory);
    } else {
        factory(jQuery, _, Marionette, fit);
    }
}(function(jQuery, _, Marionette, fit) {
    
    var urlRegexp = /(.*?)\/([^\/\.]+)\.([a-z]+)$/;
    var tinyGIF = 'data:image/gif;base64,' + 'R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs=';

    var toBoolean = function(value) {
        if (_.isBoolean(value)) return value;
        switch(String(value).toLowerCase()){
            case "true": case "yes": case "1": return true;
            case "false": case "no": case "0": case null: return false;
            default: return Boolean(value);
        }
    };
    
    var transformations = {};
    transformations['transform'] = fit.cssTransform;
    transformations['position']  = fit.cssPosition;
    transformations['margin']    = fit.cssMargin;
    
    var FitView = Marionette.SlideItemView.extend({
        
        wrapperClassName: 'fit-view',
        
        handleFocus: false,
        
        transformation: 'position', // transform, position, margin
        
        watch: false,
        cover: false,
        contain: false,
        
        hAlign: 'center', // left, center, right
        vAlign: 'center', // top, center, bottom
        
        constructor: function(options) {
            Marionette.SlideItemView.prototype.constructor.apply(this, arguments);
            var defaults = { watch: this.watch, cover: this.cover, contain: this.contain };
            options = _.extend(defaults, options);
            this.wrapperClassName = options.wrapperClassName || this.wrapperClassName;
            this.watch   = toBoolean(this.$el.data('fit-watch') || (_.has(options, 'watch') ? options.watch : this.watch));
            this.cover   = toBoolean(this.$el.data('fit-cover') || (_.has(options, 'cover') ? options.cover : this.cover));
            this.contain = toBoolean(this.$el.data('fit-contain') || (_.has(options, 'contain') ? options.contain : this.contain));
            this.hAlign = this.$el.data('h-align') || options.hAlign || this.hAlign;
            this.vAlign = this.$el.data('v-align') || options.vAlign || this.vAlign;
            this.transformation = this.$el.data('fit-transformation') || options.transformation || this.transformation;
            this.transform = this.transform || transformations[this.transformation] || fit.cssPosition;
            if (options.responsive) this.responsive(true);
        },
        
        responsive: function(bool) {
            var initialize = _.once(function() {
                this.isResponsive = true;
                var callback = function() {
                    if (this.isResponsive === true) this.onResize();
                }.bind(this);
                $(window).on('resize', _.throttle(callback, 100));
            }.bind(this));
            if (bool) initialize();
            this.isResponsive = bool;
        },
                
        fitOptions: function() {
            var hAlign = _.result(this, 'hAlign');
            var vAlign = _.result(this, 'vAlign');
            var options = { watch: this.watch, cover: this.cover, hAlign: hAlign, vAlign: vAlign };
            return options;
        },
        
        setupTransform: function() { 
            this._clearTransform();
            var options = this.fitOptions();
            
            this.$el.attr('data-h-align', options.hAlign);
            this.$el.attr('data-v-align', options.vAlign);
            
            this.triggerMethod('setup:transform', this.$wrapper, this.$el, options); // wrapper, container, options
            
            var transform = fit(this.wrapper, this.el, options, this._applyTransform.bind(this));
            if (this.watch && transform.trigger) this.watcher = transform;
        },
        
        applyTransform: function(transform, element) {
            this.transform(transform, element);
        },
        
        onBlur: function() {
            if (this.handleFocus && this.watcher) this.watcher.off();
        },
        
        onFocus: function() {
            if (this.handleFocus && this.watcher) this.watcher.on();
        },
        
        onBeforeClose: function() {
            this._clearTransform();
            Marionette.SlideItemView.prototype.onBeforeClose.apply(this, arguments);
        },
        
        onRender: function(callback) {
            this.$el.addClass(this.className);
            this.$wrapper = this.$el.children('.' + this.wrapperClassName).eq(0);
            if (!this.$wrapper.is('*')) {
                this.$wrapper = this.$el.wrapInner('<div>').children(':first');
                this.$wrapper.addClass(this.wrapperClassName);
            }
            this.wrapper = this.$wrapper[0];
            if (_.isFunction(callback)) callback.call(this);
            this.updateLayout(true);
        },
                
        refresh: function() {
            Marionette.SlideItemView.prototype.refresh.apply(this, arguments);
            this.updateLayout();
        },
        
        onResize: function() {
            this.updateLayout();
        },
        
        updateLayout: function(force) {
            if (this.watcher && this.watcher.trigger && !force) {
                this.watcher.trigger();
            } else {
                this.setupTransform();
            }
        },
        
        _clearTransform: function() {
            if (this.watcher) this.watcher.off(), delete this.watcher; // release old watcher
        },
        
        _applyTransform: function(transform, element) {
            if (this.contain) {
                transform.tx = transform.ty = 0;
                transform.width  = Math.min(transform.width, this.$el.width());
                transform.height = Math.min(transform.height, this.$el.height());
            }
            this.triggerMethod('before:transform', this.$wrapper, this.$el, transform);
            this.applyTransform(transform, element);
            this.triggerMethod('after:transform', this.$wrapper, this.$el, transform);
        },
        
    });
    
    var CoverView = FitView.extend({
        
        orientation: 'auto',   // width-height priority: auto, landscape, portrait
        minWidth:  100,        // percentage of view width
        minHeight: 100,        // percentage of view height
        rounding: 1,           // round to nearest, in px
        treshold: 100,         // percentage of size to trigger 100% coverage
        draggable: false,      // disable dragging of img/child elem
        
        fallbackSrc: tinyGIF,  // fallback for missing images
        
        constructor: function(options) {
            FitView.prototype.constructor.apply(this, arguments);
            var defaults = { minWidth: this.minWidth, minHeight: this.minHeight };
            defaults.rounding = this.rounding;
            defaults.landscape = this.landscape;
            options = _.extend(defaults, options);
            this.orientation = this.$el.data('orientation') || options.orientation || this.orientation;
            this.minWidth  = parseInt(this.$el.data('min-width')  || options.minWidth);
            this.minHeight = parseInt(this.$el.data('min-height') || options.minHeight);
            this.rounding  = _.has(options, 'rounding') ? options.rounding : this.rounding;
            this.treshold  = _.has(options, 'treshold') ? options.treshold : this.treshold;
            this.draggable = _.has(options, 'draggable') ? options.draggable : this.draggable;
            var transformation = options.elementTransformation || this.transformation;
            this.elementTransform = this.elementTransform || transformations[transformation] || fit.cssPosition;
            this.elementOptions = this.elementOptions || options.elementOptions;
        },
        
        setSrc: function(src) {
            if (this.$elem && this.$elem.is('img')) {
                var fallbackSrc = this.fallbackSrc;
                var baseSrc = this.$elem.data('src') || this.$elem.attr('src');
                this.$elem.data('src', baseSrc); // keep original
                if (_.isFunction(src)) {
                    var matches = baseSrc.match(urlRegexp);
                    if (matches) { // path, filename, ext
                        src = src(matches[1], matches[2], matches[3]);
                    }
                }
                src = src || baseSrc || fallbackSrc; // pass nothing to reset
                if (this.$elem.attr('src') !== src) {
                    this.$elem.attr('src', src);
                    this.$elem.one('load', this.updateLayout.bind(this, true));
                    this.$elem.one('error', function() {
                        $(this).attr('src', fallbackSrc);
                    });
                }
            }
        },
        
        elementFitOptions: function() {
            var hAlign = _.result(this, 'hAlign');
            var vAlign = _.result(this, 'vAlign');
            var defaults = { cover: true, hAlign: hAlign, vAlign: vAlign };
            return _.extend(defaults, _.result(this, 'elementOptions'));
        },
        
        onRender: function() {
            FitView.prototype.onRender.call(this, function() {
                this.$elem = this.$wrapper.children().eq(0);
                if (this.$elem.is('img') && this.draggable === false) {
                    this.$elem.on('mousedown', function(event) {
                        event.preventDefault();
                    });
                }
                if (this.$elem.is('img')) {
                    this.$elem.one('load', this.onResize.bind(this));
                }
            });
        },
        
        onSetupTransform: function(element, container, options) {
            var ratio = this.$elem.width() / this.$elem.height();
            
            var orientation = this.orientation;
            if (orientation === 'auto' || !orientation) {
                orientation = (ratio > 1 ? 'landscape' : 'portrait');
            }
            
            this.$el.attr('data-orientation', orientation);
            
            if (orientation === 'landscape') {
                var maxWidth = container.width();
                var height = container.height();
                var width = height * ratio;
                if (this.rounding) width = (Math.ceil(width / this.rounding) * this.rounding);
                width = Math.max(width, maxWidth / (100 / this.minWidth));
                width = Math.min(width, maxWidth);
                if ((width / maxWidth * 100) > this.treshold) width = maxWidth;
            } else {
                var maxHeight = container.height();
                var width = container.width();
                var height = width / ratio;
                if (this.rounding) height = (Math.ceil(height / this.rounding) * this.rounding);
                height = Math.max(height, maxHeight / (100 / this.minHeight));
                height = Math.min(height, maxHeight);
                if ((height / maxHeight * 100) > this.treshold) height = maxHeight;
            }
            
            element.width(width).height(height);
        },
    
        onAfterTransform: function(element, container, transform) {
            element.fit(this.$elem, this.elementFitOptions(), this.elementTransform);
        },
        
        _clearTransform: function() {
            if (this.$elem) this.$elem.attr('style', '');
            FitView.prototype._clearTransform.call(this);
        }
    
    });
    
    Marionette.FitView = FitView;
    Marionette.CoverView = CoverView;
        
    return Marionette.FitView;
    
}));