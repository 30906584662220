
(function (factory) {
    // requires iscroll.js idangerous.swiper.js (custom)
    // backbone, backbone.marionette, backbone.courier
    if (typeof define === 'function' && define.amd) {
        define('marionette.slideview',['jquery', 'underscore', 'backbone', 'marionette', 'swiper', 'backbone.courier',
            'swiper.progress', 'iscroll'], factory);
    } else {
        factory(jQuery, _, Backbone, Marionette, Swiper, Backbone.Courier);
    }
}(function($, _, Backbone, Marionette, Swiper, Courier) {
    
    Backbone.Courier = Backbone.Courier || Courier;
    
    var tinyGIF = 'data:image/gif;base64,' + 'R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs=';
    
    var htmlRegexp = /<\/?\w+((\s+\w+(\s*=\s*(?:".*?"|'.*?'|[^'">\s]+))?)+\s*|\s*)\/?>/;
    
    var chromeMatches = navigator.userAgent.match(/Chrom(e|ium)\/([0-9]+)\./) || [];
    var isBuggyChrome = parseInt(chromeMatches[2]) === 33;
    
    var animMethod = _.isFunction($.fn.velocity) ? 'velocity' : 'animate';
    
    _.mixin({
        
        segments: function(path, seperator) {
            seperator = seperator || '/';
            if (_.isArray(path)) {
                var segments = [].concat(path);
            } else {
                path = (path || '').replace(/\.[^\.]+$/, '');
                var segments = path.split(seperator);
            }
            return _.compact(segments);
        },
        
        isHTML: function(value, allowElements, allowJQuery) {
            if (allowElements && _.isElement(value)) return true;
            if (allowJQuery && _.isJQuery(value)) return true;
            return _.isString(value) && htmlRegexp.test(value);
        },
        
        isJQuery: function(value) {
            return value instanceof $ && value.length > 0;
        },
        
        delayed: function(fn, wait) {
            return function() {
                var args = arguments;
                setTimeout(function() { fn.apply(null, args); }, wait || 0); 
            };
        },
        
        deferred: function(fn) {
            return function() {
                var args = arguments;
                _.defer(function() { fn.apply(null, args); });
            };
        }
        
    });
    
    Swiper.prototype.plugins.marionette = function(swiper, params) {
        var view = (params || {}).view;
        if (!view) return;
        var method = _.isFunction(view.triggerMethod) ? 'triggerMethod' : 'trigger';
        var hooks = {
            beforeResizeFix: view[method].bind(view, 'before:resize'),
            afterResizeFix:  view[method].bind(view, 'after:resize'),
            onSwipePrev:     view[method].bind(view, 'swipe:prev'),
            onSwipeNext:     view[method].bind(view, 'swipe:next'),
            onAutoplayStart: view[method].bind(view, 'autoplay:start'),
            onAutoplayStop:  view[method].bind(view, 'autoplay:stop'),
            numberOfSlidesChanged: view[method].bind(view, 'change'),
            onFirstInit: function() {}
        }
        return hooks;
    };
    
    var Loadable = function(view) {
        var logic = {
            load: function(url, data, done) {
                if (_.isFunction(data)) done = data, data = {};
                var options = {}, complete;
                options.url = url || this.url;
                if (data) options.data = data;
                
                var trigger = function(event) {
                    var args = [event, this].concat({ args: _.rest(arguments) });
                    this.trigger.apply(this, args);
                    if (_.isObject(this.delegate) 
                        && _.isFunction(this.delegate.trigger)) {
                        this.delegate.trigger.apply(this.delegate, args);
                    }
                    if (this.spawn) this.spawn(event, args);
                }.bind(this);
                
                var before = function() {
                    var dfd = $.Deferred();
                    trigger('load:before', url, data);
                    this.loadBefore(dfd.resolve);
                    return dfd;
                }.bind(this);
                
                options.beforeSend = function(xhr) {
                    trigger('load:send', xhr);
                    this.loadSend(xhr);
                }.bind(this);
                
                var ajax = function() {
                    xhr = $.ajax(options);
                    complete = trigger.bind(this, 'load:done', xhr);
                    xhr.fail(function() { this.url = null; }.bind(this));
                    xhr.fail(this.loadFail.bind(this));
                    xhr.fail(trigger.bind(this, 'load:fail', xhr));
                    xhr.done(function() { this.url = options.url; }.bind(this));
                    xhr.always(this.loadAfter.bind(this));
                    xhr.always(trigger.bind(this, 'load:after', xhr));
                    return xhr;
                }.bind(this);
                
                if (_.isFunction(done)) { // override
                    var next = done.bind(null, this);
                } else {
                    var next = this.loadDone.bind(this);
                }
                
                if (_.isString(options.url)) {
                    return before().then(ajax).then(next).then(complete);
                } else {
                    return before().then(next).then(complete);
                }
            },
            
            reload: function(data, done) {
                return this.load(this.url, data, done);
            },
            
            loadSend: function(xhr) {},    
            loadBefore: function(callback) { callback(); },
            loadAfter: function(data, status, xhr) {},
            loadDone: function(data, status, xhr) {},
            loadFail: function(xhr, status, msg) {}
        }
        
        if (!_.isFunction(view.load)) view.load = logic.load.bind(view);
        if (!_.isFunction(view.reload)) view.reload = logic.reload.bind(view);
        
        if (!_.isFunction(view.loadSend)) view.loadSend = logic.loadSend.bind(view);
        if (!_.isFunction(view.loadBefore)) view.loadBefore = logic.loadBefore.bind(view);
        if (!_.isFunction(view.loadAfter)) view.loadAfter = logic.loadAfter.bind(view);
        if (!_.isFunction(view.loadDone)) view.loadDone = logic.loadDone.bind(view);
        if (!_.isFunction(view.loadFail)) view.loadFail = logic.loadFail.bind(view);
    };
    
    var extractContent = function(content, options) {
        if (_.isHTML(content)) {
            var html = _.isString(content) ? $('<div>').html(content) : null;
            if (_.isNull(html) && _.isElement(content)) html = $(content);
            if (_.isNull(html) && _.isJQuery(content)) html = content;
            if (!_.isJQuery(html)) html = $('<div>');
            options = options || {};
            if (_.isObject(this.delegate) && _.isFunction(this.delegate.extractHTML)) {
                html = this.delegate.extractHTML(html, options);
            } else if (_.isFunction(this.extractHTML)) {
                html = this.extractHTML(html, options);
            }
        } else {
            var html = content;
        }
        return _.isJQuery(html) ? html : $('<div>').html(html);
    };
    
    Marionette.Loadable = Loadable;
    
    var BaseSlideItemView = Marionette.ItemView.extend({
        
        className: 'swiper-slide',
        
        autofetch: false,
        
        autoroute: false, // whether to update the route due to internal fetches
        
        constructor: function(options) {
            Marionette.ItemView.prototype.constructor.apply(this, arguments);
            Backbone.Courier.add(this);
            Loadable(this);
            this.el.view = this; // keep reference
            options = _.extend({}, options);
            if (!options.nestedView) {
                var nestedOptions = _.extend({}, options, this.nestedViewOptions);
                var nestedViewClass = options.nestedViewClass || this.nestedViewClass;
                if (nestedViewClass) options.nestedView = new nestedViewClass(nestedOptions);
            }
            if (options.nestedView) this.setNestedView(options.nestedView);
            if (options.content) this.setContent(options.content, true);
            if (_.isString(options.name)) this.name(options.name);
            if (_.isString(options.label)) this.label(options.label);
            if (_.isString(options.tag)) this.tag(options.tag);
            if (_.isString(options.anchor)) this.anchor(options.anchor);
            if (_.isString(options.url)) this.url = options.url;
            if (this.model instanceof Backbone.Model) {
                this.listenTo(this.model, 'change', this.render);
                this.listenTo(this.model, 'change:url', this.reload);
            }
            this.on('focus', this.updateUI);
            this.triggerMethod('construct', options);
        },
        
        setNestedView: function(view) {
            if (view instanceof Backbone.View) {
                if (this.nestedView && _.isFunction(this.nestedView.destroy)) {
                    this.nestedView.destroy();
                }
                this.nestedView = view;
                this.nestedView.parentView = this;
                this.nestedView.trigger('parent:assign', this);
                return this.nestedView;
            }
        },
        
        name: function(name) {
            if (arguments.length === 1) this.$el.attr('data-name', name); // attribute!
            if (this.model instanceof Backbone.Model) {
                if (arguments.length === 1) this.model.set('name', name);
                return this.model.get('name') || this.model.get('id') || this.id || this.cid;
            } else {
                return this.$el.data('name') || this.id || this.cid;
            }
        },
        
        label: function(label) {
            if (this.model instanceof Backbone.Model) {
                if (arguments.length === 1) this.model.set('label', label);
                return this.model.get('label');
            } else {
                if (arguments.length === 1) this.$el.data('label', label);
                return this.$el.data('label') || this.$el.attr('label');
            }
        },
        
        anchor: function(name) {
            if (arguments.length === 1) this.$el.data('anchor', name);
            return this.$el.data('anchor') || this.$el.data('name');
        },
        
        tag: function(tag) {
            if (arguments.length === 1) this.$el.data('tag', tag);
            return this.$el.data('tag');
        },
        
        index: function() {
            return this.el.index();
        },
        
        get: function(path) {
            return this; // could return subview here
        },
        
        setVisibility: function(bool) {
            this.$el.css('visibility', bool ? 'visible' : 'hidden');
        },
        
        destroy: function() {
            try { this.el.remove(); } catch(e) {};
            Marionette.ItemView.prototype.destroy.apply(this, arguments);
        },
        
        detach: function() {
            try { this.el.remove(); } catch(e) {};
            if (this.container) this.container.stopListening(this);
            return this;
        },
        
        fetch: function(path, options, callback) {
            if (_.isFunction(options)) callback = options, options = {};
            if (_.isNumber(options)) options = { speed: options };
            options = _.extend({}, options);
            var fetch = options.fetch !== false && _.result(this, 'autofetch');
            if ((fetch || options.fetch === true) && (options.url || options.path)) {
                var xhr = this.load(options.url || options.path);
                if (_.isFunction(callback)) xhr.done(callback.bind(null, this));
                return xhr;
            } else {
                if (_.has(options, 'url')) this.url = options.url;
                var dfd = $.Deferred();   
                if (_.isFunction(callback)) dfd.done(callback);
                return dfd.resolveWith(null, this);
            }
        },
        
        loadDone: function(data, status, xhr) {
            return this.setContent(data);
        },
        
        loadFail: function(xhr, status, msg) {
            return this.setContent('');
        },
        
        setFlags: function(options) {},
        
        canSwipe: function() {
            return true;
        },
        
        canNavigate: function (direction, ui) { // delegates by default
            if (this.nestedView && _.isFunction(this.nestedView.canNavigate)) {
                return this.nestedView.canNavigate(direction, ui);
            }
        },
        
        navigate: function(direction, options, callback) { // delegates by default
            if (this.nestedView && _.isFunction(this.nestedView.navigate)) {
                return this.nestedView.navigate(direction, options, callback);
            }
        },
        
        isScrollable: function(direction) {
            var scrollable = _.isObject(this.scroller) && this.scroller.enabled;
            if (scrollable && !_.isString(direction)) {
                if (this.scroller.options.scrollX && this.scroller.hasHorizontalScroll) return true;
                if (this.scroller.options.scrollY && this.scroller.hasVerticalScroll) return true;
                return false;
            }
            return scrollable && (
                (direction === 'vertical' && this.scroller.hasVerticalScroll) || 
                (direction === 'horizontal' && this.scroller.hasHorizontalScroll)
            );
        },
        
        isTopActive: function(strict) {
            var scrollable = this.isScrollable() && this.scroller.options.scrollY;
            if (strict && scrollable) {
                return this.scroller.y === 0;
            } else if (this.topTreshold && scrollable) {
                return this.scroller.y + this.topTreshold >= 0;
            }
            return false;
        },
        
        isBottomActive: function(strict) {
            var scrollable = this.isScrollable() && this.scroller.options.scrollY;
            if (strict && scrollable) {
                return this.scroller.y === this.scroller.maxScrollY;
            } else if (this.bottomTreshold && scrollable) {
                return this.scroller.y < this.scroller.maxScrollY + this.bottomTreshold;
            }
            return false;
        },
        
        isHorizontal: function() {
            return this.isScrollable('horizontal');
        },
        
        isActive: function() {
            var active = this.$el.closest('.swiper-slide-active').length > 0;
            return active && this.el.isActive();
        },
        
        isDisabled: function() {
            return !!this.disabled;
        },
        
        isVisible: function() {
            return this.$el.is('.swiper-slide-visible');
        },
        
        isClickable: function() {
            return this.$el.closest('.swipe, .touched').length === 0;
        },
        
        showPagination: function() {
            return true;
        },
        
        enable: function() {
            this.disabled = false;
        },
        
        disable: function() {
            this.disabled = true;
        },
        
        setContent: function(content, skipRender) {
            content = this.extractContent(content, this.options);
            
            if (_.isElement(content)) content = content.outerHTML;
            if (_.isJQuery(content)) content = content.html();
            
            if (this.model instanceof Backbone.Model) {
                this.model.set('content', content);
            } else {
                this.content = content;
            }
            
            if (this.nestedView && _.isFunction(this.nestedView.setContent)) {
                this.nestedView.setContent(content, true);
            }
            if (!skipRender) return this.render();
        },
        
        extractContent: extractContent,
        
        serializeData: function() {
            if (this.model instanceof Backbone.Model 
                || this.collection instanceof Backbone.Collection) {
                return Marionette.ItemView.prototype.serializeData.apply(this, arguments);
            } else if (_.isObject(this.data)) {
                return this.data;
            } else if (this.content) {
                return { content: this.content }
            } else {
                return {};
            }
        },
        
        render: function() { // could return promise
            if (this.nestedView instanceof Backbone.View) {
                var $nested = this.$el.children('.nested').eq(0);
                if ($nested.length === 0) {
                    $nested = this.$el.wrapInner('<div class="nested">').children(':first');
                }
                this.performRender(function() {
                    this.renderNested(this.nestedView, $nested);
                });
            } else if (Marionette.getOption(this, 'template')) {
                Marionette.ItemView.prototype.render.apply(this, arguments);
            } else if (this.model) {
                this.performRender(function() {
                    this.$el.html(this.model.toString());  
                });
            } else if (_.isString(this.content)) {
                this.performRender(function() {
                    this.$el.html(this.content);
                });
            } else {
                this.performRender();
            }
        },
        
        renderNested: function(view, selector) {
            var $element = (selector instanceof jQuery) ? selector : this.$(selector);
            view.setElement($element).render();
        },
        
        performRender: function(callback) {
            this.isDestroyed = false;
            
            this.spawn('before:render');
            this.triggerMethod('before:render', this);
            this.triggerMethod('before:render', this);
            
            if (callback) callback.call(this);
            
            this.bindUIElements();
            
            this.triggerMethod('render', this);
            this.triggerMethod('render', this);
            this.spawn('render');
        },        
        
        refresh: function() {
            this.render(); // default implementation
        },
        
        updateUI: function() {},
        
        onResize: function() {},
        
        onBeforeClose: function() {
            if (this.isDestroyed) { return; }
            if (this.removeScroller) this.removeScroller();
            this.$el.find('img').attr('src', tinyGIF);
            this.detach();
            this.el.view = null;
        },
        
        triggerMethod: function(event) {
            if (this.nestedView instanceof Backbone.View) {
                var method = 'trigger';
                if (_.isFunction(this.nestedView.triggerMethod)) method += 'Method';
                this.nestedView[method].apply(this.nestedView, arguments);
            }
            return Marionette.ItemView.prototype.triggerMethod.apply(this, arguments);
        }
    
    });
    
    Marionette.BaseSlideItemView = BaseSlideItemView;
    
    var SlideItemView = BaseSlideItemView.extend({
        
        className: 'swiper-slide',
        
        scrollTreshold: 25,
        pullTreshold: 25,
        topTreshold: 0, 
        bottomTreshold: 0,
        
        constructor: function(options) {
            BaseSlideItemView.prototype.constructor.apply(this, arguments);
            this.scroll = _.extend({}, this.scroll, (options || {}).scroll);
            if (_.throttle && (options || {}).throttle !== false) {
                this.onScrollUpdate = _.throttle(this.onScrollUpdate.bind(this), 100);
            }
        },
        
        render: function() {
            this.removeScroller(); // always remove before rendering
            
            var ret = BaseSlideItemView.prototype.render.apply(this, arguments);
            
            var pane = this.$el.find('> .pane').eq(0);
            var wrapped = pane.length > 0;
            var scrolls = wrapped || this.$el.hasClass('scrollable');
            scrolls = scrolls || Marionette.getOption(this, 'scrollable') === true;
            scrolls = scrolls || this.$el.find('> .scrollable').length > 0;
            
            if (scrolls) {
                if (!wrapped) this.$el.wrapInner('<div class="pane"/>');
                this.$el.css('position', 'relative');
                this.ensureScroller();
            } else {
                if (wrapped) this.$el.unwrap();
            }
            
            this.triggerMethod('after:render'); // specific call
            
            return ret;
        },
        
        refresh: function() {
            BaseSlideItemView.prototype.refresh.apply(this, arguments);
            _.defer(this.updateUI.bind(this));
        },
        
        updateUI: function() {
            BaseSlideItemView.prototype.updateUI.apply(this, arguments);
            if (_.isObject(this.scroller)) this.scroller.refresh();
        },
        
        ensureScroller: function() {
            if (this.scroller) return this.scroller.refresh();
            
            var defaults = {
                HWCompositing: !isBuggyChrome,
                mouseWheel: true,
                wheelEvent: true,
                scrollbars: true,
                fadeScrollbars: true,
                interactiveScrollbars: true,
                keyBindings: true,
                scrollX: false,
                probeType: 2,
                tap: true
            };
            
            var scrollOptions = Marionette.getOption(this, 'scroll');
            var options = _.extend(defaults, this.constructor.config, scrollOptions);
            this.scroller = new IScroll(this.el, options);
            this.triggerMethod('init:scroller', this.scroller);
            this.$el.data('iscroll', this.scroller);
            
            this.scroller.on('beforeScrollStart', this.triggerScrollEvent.bind(this, 'before:scroll:start'));
            this.scroller.on('scrollStart', this.triggerScrollEvent.bind(this, 'scroll:start'));
            this.scroller.on('scroll', this.triggerScrollEvent.bind(this, 'scroll:move'));
            this.scroller.on('scrollEnd', this.triggerScrollEvent.bind(this, 'scroll:end'));
            this.scroller.on('scrollCancel', this.triggerScrollEvent.bind(this, 'scroll:cancel'));
            this.scroller.on('wheel', this.triggerScrollEvent.bind(this, 'scroll:wheel'));
            
            if (options.flick) {
                this.scroller.on('flick', this.triggerScrollEvent.bind(this, 'scroll:flick'));
            }
            if (options.tap) {
                this.scroller.on('tap', this.triggerScrollEvent.bind(this, 'tap'));
            }
            if (options.zoom) {
                this.scroller.on('zoomStart', this.triggerScrollEvent.bind(this, 'zoom:start'));
                this.scroller.on('zoomEnd', this.triggerScrollEvent.bind(this, 'zoom:end'));
            }
            this.onScrollUpdate();
        },
        
        triggerScrollEvent: function(name) {
            if (this.isScrollable()) {
                this.triggerMethod.apply(this, arguments);
                this.spawn(name);
            }
        },
        
        scrollTo: function(x, y, time, easing) {
            if (this.scroller) this.scroller.scrollTo.call(this.scroller, arguments);
        },
        
        scrollBy: function(x, y, time, easing) {
            if (this.scroller) this.scroller.scrollBy.apply(this.scroller, arguments);
        },
        
        scrollToElement: function(el, time, offsetX, offsetY, easing) {
            if (this.scroller) this.scroller.scrollToElement.apply(this.scroller, arguments);
        },
        
        cancelScroll: function() {
            if (this.scroller) this.scroller.initiated = false;
        },
            
        setScrollable: function(bool) {
            if (_.isObject(this.scroller)) {
                this.scroller[bool ? 'enable' : 'disable']();
                if (bool) this.refresh();
            } else if (bool) {
                this.$el.addClass('scrollable');
                this.render();
            }
        },
        
        removeScroller: function() {
            if (this.$el.data('iscroll')) {
                if (this.scroller) this.scroller.destroy();
                this.scroller = null;
            }
        },
        
        onBeforeScrollStart: function() {
            if (!this.isClickable()) this.cancelScroll();
        },
        
        onScrollMove: function() {
            var treshold = this.pullTreshold || 0;
            this.pullUp = this.pullDn = this.pullLft = this.pullRgt = false;
            if (this.scroller.options.scrollY) {
                if (!this.scroller.options.bounce) {
                    this.pullUp = this.scroller.directionY === -1 && this.scroller.y == 0;
                    this.pullDn = this.scroller.directionY === 1 && this.scroller.y == this.scroller.maxScrollY;
                } else {
                    this.pullUp = this.scroller.directionY === -1 && this.scroller.y > treshold;
                    this.pullDn = this.scroller.directionY === 1 && this.scroller.y < this.scroller.maxScrollY - treshold;
                }
            }
            if (this.scroller.options.scrollX) {
                if (!this.scroller.options.bounce) {
                    this.pullLft = this.scroller.directionX === 1 && this.scroller.x == 0;
                    this.pullRgt = this.scroller.directionX === -1 && this.scroller.x == this.scroller.maxScrollX;
                } else {
                    this.pullLft = this.scroller.directionX === 1 && this.scroller.x < -treshold;
                    this.pullRgt = this.scroller.directionX === -1 && this.scroller.x > this.scroller.maxScrollX + treshold;
                }
            }
            this.onScrollUpdate();
        },
        
        onScrollUpdate: function(skipEvent) {
            if (!skipEvent) {
                this.triggerScrollEvent('scroll', this);
            }
            var scrollable = this.isScrollable('vertical');
            if (scrollable && this.topTreshold) {
                var active = this.isTopActive();
                if (this.scroller.topActive != active) {
                    this.scroller.topActive = active;
                    var name = 'scroll:top:' + (active ? 'active' : 'inactive');
                    this.triggerScrollEvent(name, this);
                }
            }
            if (scrollable && this.bottomTreshold) {
                var active = this.isBottomActive();
                if (this.scroller.bottomActive != active) {
                    this.scroller.bottomActive = active;
                    var name = 'scroll:bottom:' + (active ? 'active' : 'inactive');
                    this.triggerScrollEvent(name, this);
                }
            }
        },
        
        onScrollEnd: function(event) {
            if (this.pullUp)  this.spawn('pull:up'),    this.triggerMethod('pull:up', this);
            if (this.pullDn)  this.spawn('pull:down'),  this.triggerMethod('pull:down', this);
            if (this.pullLft) this.spawn('pull:left'),  this.triggerMethod('pull:left', this);
            if (this.pullRgt) this.spawn('pull:right'), this.triggerMethod('pull:right', this);
            this.onScrollUpdate(true);
            this.pullUp = this.pullDn = this.pullLft = this.pullRgt = false;
        },
        
        onScrollWheel: function(event, newX, newY) {
            this.pullUp = this.pullDn = this.pullLft = this.pullRgt = false;
            var treshold = this.scrollTreshold || 0;
            if (this.scroller.options.scrollY) {
                if (!this.scroller.hasVerticalScroll) {
                    if (Math.abs(event.deltaY) < treshold) event.stopPropagation();
                } else if (newY > treshold) {
                    event.stopPropagation();
                    this.pullUp = true;
                } else if (newY < 0 && newY < this.scroller.maxScrollY - treshold) {
                    event.stopPropagation();
                    this.pullDn = true;
                }
            }
            if (this.scroller.options.scrollX) {
                if (!this.scroller.hasHorizontalScroll) {
                    if (Math.abs(event.deltaX) < treshold) event.stopPropagation();
                } else if (newX > treshold) {
                    event.stopPropagation();
                    this.pullLft = true;
                } else if (newX < 0 && newX < this.scroller.maxScrollX - treshold) {
                    event.stopPropagation();
                    this.pullRgt = true;
                }
            }
        }
    
    });
    
    Marionette.SlideItemView = SlideItemView;
        
    var SlideView = Marionette.ItemView.extend({
        
        className: 'swiper-container',
        
        childView: SlideItemView,
        
        holdTreshold: 100,
        
        options: {},
        
        onMessages: {
            '*': function(message) {
                var prefix = message.name.indexOf('view:') === 0 ? '' : 'view:';
                this.triggerMethod(prefix + message.name, message.source, message.data);
            },
            'view:focus': 'viewFocus',
            'view:blur': 'viewBlur',
            'getRoot!': 'getRoot'
        },
        
        constructor: function(options) {
            var implementationEvents = _.extend({}, this.events);
            this.events = function() {
                var events = _.result(this, 'prototypeEvents');
                return _.extend({}, events, implementationEvents);
            }.bind(this);
            
            Marionette.View.prototype.constructor.apply(this, arguments);
            Backbone.Courier.add(this); // keeps reference in data('view')
            Loadable(this);
            options = _.extend({}, options);
            this.state = {}; // compatibility with PagerView
            this._navigation = {};
            this.activeContainer = this;
            var defaults = { touchTimeout: 400 };
            var omit = ['name', 'label', 'tag', 'config', 'collection', 'el', 'delegate'];
            this.options = _.omit(_.extend(defaults, this.options, options), omit);
            this.config = _.extend({}, this.config, options.config);
            if (_.isObject(options.delegate)) this.delegate = options.delegate;
            if (_.isString(options.name)) this.name(options.name);
            if (_.isString(options.label)) this.label(options.label);
            if (_.isString(options.tag)) this.tag(options.tag);
            if (_.isString(options.anchor)) this.anchor(options.anchor);
            if (options.collection) this.setCollection(options.collection);
            this.on('view:pull:up',    this.onViewPull.bind(this, 'up'));
            this.on('view:pull:down',  this.onViewPull.bind(this, 'down'));
            this.on('view:pull:left',  this.onViewPull.bind(this, 'left'));
            this.on('view:pull:right', this.onViewPull.bind(this, 'right'));
            this.on('after:change',    this.updateUI.bind(this));
            this.on('after:resize',    this.onResize.bind(this));
            this.once('view:focus',    this._onReady.bind(this));
            this.triggerMethod('construct');
        },
        
        prototypeEvents: function() {
            return { 'click [data-nav]': 'onNavigate' }
        },
        
        name: function(name) {
            if (arguments.length === 1) this.$el.data('name', name);
            return this.$el.data('name') || this.id || this.cid;
        },
        
        label: function(label) {
            if (arguments.length === 1) this.$el.data('label', label);
            return this.$el.data('label') || this.$el.attr('label') || this.name();
        },
        
        anchor: function(name) {
            if (arguments.length === 1) this.$el.data('anchor', name);
            return this.$el.data('anchor') || this.$el.data('name');
        },
        
        tag: function(tag) {
            if (arguments.length === 1) this.$el.data('tag', tag);
            return this.$el.data('tag');
        },
        
        index: function() {
            return this.el.index ? this.el.index() : -1;
        },
        
        setVisibility: function(bool) {
            this.$el.css('visibility', bool ? 'visible' : 'hidden');
        },
        
        setParams: function(params) {
            if (_.isObject(params)) {
                _.extend(this.swiper.params, params);
                _.extend(this.config, params);
            }
            return this.swiper.params;
        },
        
        getRoot: function() {
            try {
                return this.spawn('getRoot!');
            } catch(e) {
                return this;
            }
        },
        
        getParent: function() {
            var closest = this.$el.parent().closest('.swiper-container');
            if (closest.length > 0) return closest.data('view');
        },
        
        getActive: function() {
            var slide = $(this.swiper.wrapper).find('.swiper-slide-active')[0];
            if (slide && slide.view) return slide.view;
        },
        
        getWrapper: function() {
            return $(this.swiper.wrapper);
        },
        
        isActive: function() {
            var active = this.$el.is('.swiper-slide-active');
            return active || !this.getParent() || !this.container;
        },
        
        isEmpty: function() {
            return this.swiper.slides.length === 0;
        },
        
        isHorizontal: function() {
            return this.swiper.params.mode === 'horizontal';
        },
        
        isVisible: function(pos) {
            if (arguments.length) {
                var view = this.get(pos);
                return view && view.isVisible();
            }
            if (!this.$el.is('.swiper-slide')) return true;
            return this.$el.is('.swiper-slide-visible');
        },
        
        isClickable: function() {
            return this.$el.closest('.swipe, .touched').length === 0;
        },
        
        isScrollable: function(direction) {
            var active = this.get(':active');
            return active && active.isScrollable(direction);
        },
        
        isSmallViewport: function() {
            var width = this.$el.width(), height = this.$el.height();
            var landscape = width > height;
            return (landscape && width < 992) || (!landscape && height < 768);
        },
        
        isFirst: function() {
            return this.swiper.activeIndex === 0 || isNaN(this.swiper.activeIndex);
        },
        
        isLast: function() {
            return this.swiper.activeIndex === this.swiper.slides.length - 1;
        },
        
        isDisabled: function() {
            return !!this.disabled;
        },
        
        preventUI: function(bool) {
            this.preventUIUpdate = bool;
            if (!this.preventUIUpdate) this.updateUI();
        },
        
        updateUI: function() {
            if (this.preventUIUpdate) return;
            this.state.currentPage = this.swiper.activeIndex + 1;
            this.state.totalPages = this.swiper.slides.length;
            this.updatePagination();
            this.updateNavigation();
        },
        
        showPagination: function() {
            if (!this.isVisible()) return false;
            var active = this.get(':active');
            return active && active.showPagination();
        },
        
        updatePagination: function() {
            var active = this.get(':active');
            this.trigger('paginate');
            if (active instanceof SlideView) {
                active.updatePagination();
            } else if (this.swiper && this.swiper.params.pagination) {
                this.triggerMethod('before:pagination', this);
                var pagination = $(this.swiper.params.pagination).eq(0);
                if (pagination.is('*')) {
                    if (this.showPagination()) {
                        this.swiper.createPagination();
                        pagination[this.swiper.slides.length > 1 ? 'show' : 'hide']();
                    } else {
                        pagination.hide();
                    }
                }
                this.triggerMethod('after:pagination', this);
            }
        },
        
        canSwipe: function() {
            return true;
        },
        
        canNavigate: function(direction, ui) {
            var container;
            
            direction = this.normalizeDirection(direction);
            
            var check = function(view) {
                var horizontal = view.isHorizontal();
                if (horizontal && direction === 'left' && view.has(':prev')) {
                    return view;
                } else if (horizontal && direction === 'right' && view.has(':next')) {
                    return view;
                } else if (!horizontal && direction === 'up' && view.has(':prev')) {
                    return view;
                } else if (!horizontal && direction === 'down' && view.has(':next')) {
                    return view;
                }
            }
            
            var active = this.getRoot().activeContainer;
            
            var activeView = active.get(':active');
            
            if (activeView instanceof BaseSlideItemView) { // active slide
                container = activeView.canNavigate(direction, ui);
                if (container === false) return; // explicit false: immediately return
            }
            
            if (!container) { // container slide view, if any
                if (this !== active) container = check(active);
            }
            
            if (!container) container = check(this); // current slide view
            
            if (!container) { // parent slide view, if any
                var parent = this.getParent();
                if (parent && parent !== active) container = check(parent);
            }
            
            return container;
        },
        
        beforeNavigate: function(view, direction, nav, callback) {
            callback(nav);
        },
        
        navigate: function(direction, options, callback) {
            var view = this.canNavigate(direction);
            if (view instanceof Marionette.ItemView) {
                direction = this.normalizeDirection(direction);
                var isPrev = direction === 'left' || direction === 'up';
                var nav = isPrev ? ':prev' : ':next';
                this.beforeNavigate(view, direction, nav, function(pos) {
                    options = _.extend({ navigate: nav }, options);
                    if (_.isNumber(pos) || _.isString(pos)) {
                        view.show(pos, options, callback);
                    } else {
                        if (_.isFunction(callback)) callback(direction, pos, options);
                    }
                });
            }     
        },
        
        normalizeDirection: function(direction) {
            if (direction === ':prev') {
                return this.isHorizontal() ? 'left' : 'up';
            } else if (direction === ':next') {
                return this.isHorizontal() ? 'right' : 'down';
            }
            return direction;
        },
        
        onNavigate: function(event) {
            var $target = $(event.target).closest('[data-nav]'), view;
            var direction = $target.attr('data-nav');
            if (direction && (view = this.canNavigate(direction))) {
                view.navigate(direction);
                if (event) event.preventDefault();
            }
        },
        
        toggleNavigation: function(bool) {
            var root = this.getRoot();
            root.options.disableNavigation = !bool;
            if (bool) {
                this.updateNavigation();
            } else {
                this.setNavigation('all', false);
            }
        },
        
        updateNavigation: function() {
            var root = this.getRoot();
            var container = root.activeContainer;
            var active = container.get(':active');
            if (root.options.disableNavigation) return false;
            root.triggerMethod('before:navigation', this, container, active);
            this.setNavigation('left',  !!container.canNavigate('left', true));
            this.setNavigation('right', !!container.canNavigate('right', true));
            this.setNavigation('up',    !!container.canNavigate('up', true));
            this.setNavigation('down',  !!container.canNavigate('down', true));
            root.triggerMethod('after:navigation', this, container, active);
            return true;
        },
        
        setNavigation: function(direction, bool) {
            var root = this.getRoot();
            if (Marionette.getOption(root, 'navigation')) {
                if (direction === 'all') {
                    var elem = $(_.compact([
                        root.navigationElement('left'), root.navigationElement('right'), 
                        root.navigationElement('up'), root.navigationElement('down')
                    ]));
                } else if (direction === 'horizontal') {
                    var elem = $(_.compact([root.navigationElement('left'), root.navigationElement('right')]));
                } else if (direction === 'vertical') {
                    var elem = $(_.compact([root.navigationElement('up'), root.navigationElement('down')]));
                } else {
                    var elem = $(root.navigationElement(direction));
                }
                elem[bool ? 'removeClass' : 'addClass']('disabled');
            }
        },
        
        parseRoute: function(route) {
            return route;
        },
        
        updateRoute: function(route, replace) {
            var root = this.getRoot();
            var container = root.activeContainer;
            var active = container.get(':active') || container.get(':first');
            var force = route === true;
            if (force) route = null;
            
            if (root._silent) return;
            
            if (route) route = this.parseRoute(route);
            
            if (route && route !== root.route) {
                root.trigger('route', route, container, active, replace);
            } else {
                var segments = [];
                if (container !== root) segments.push(container.anchor());
                if (active) segments.push(active.anchor());
                route = this.parseRoute(_.compact(segments).join('/'));
                
                if (!(active && active.autoroute)) {
                    route = active === root.get(0) ? '' : route;
                }
                
                if (route !== root.route || force) {
                    root.trigger('route', route, container, active, replace);
                }
            }
            root.route = route;
        },
        
        setCollection: function(collection) {
            if (this.collection instanceof Backbone.Collection) {
                this.stopListening(this.collection);
                this.collection = null;
                this.clear();
            }
            if (collection instanceof Backbone.Collection) {
                this.collection = collection;
                this.listenTo(this.collection, 'all', this.collectionChanged);
            }
        },
        
        collectionChanged: function(name, subject) {
            this.trigger.apply(this, ['collection:' + name].concat(_.rest(arguments)));
            if (name === 'add' && _.has(arguments[3] || {}, 'at')) {
                var options = _.pick(arguments[3] || {}, 'display', 'show');
                this.insert(arguments[3].at, subject, options);
            } else if (name === 'add') {
                var options = _.pick(arguments[3] || {}, 'display', 'show');
                this.append(subject, options);
            } else if (name === 'remove' || name === 'destroy') {
                this.remove(subject);
            } else if (name === 'reset' || name === 'sort') {
                this.clear();
                subject.each(this.append.bind(this));
            } else if (name.indexOf('change') === 0) {
                this.refresh();
            }
        },
                
        fetch: function(path, options, callback) {
            var defaults = {}, idx = -1;
            
            if (_.isUndefined(path) || _.isNull(path)) {
                return this.show(0, options, callback);
            }
            
            if (_.isString(path) && ((idx = path.lastIndexOf('#')) > -1 
                || (idx = path.lastIndexOf('@')) > -1)) {
                defaults.route = path.substr(idx + 1) || null;
                path = path.substr(0, idx);
            }
            
            var root = this.getRoot();
            
            root._silent = true; // stop route updates
            
            var segments = [].concat(this._parsePath(path, defaults));
            var pathSegments = [].concat(segments);            
            if (_.isFunction(options)) callback = options, options = {};
            if (_.isNumber(options)) options = { speed: options };
            options = _.extend({ path: path, segments: segments }, defaults, options);
            var opts = options.display ? 0 : (options.show === false ? { _noop: true } : options);
            var firstSegment = segments.shift();
            
            if (_.isString(firstSegment)) options.name = firstSegment;
            var item = _.isObject(firstSegment) ? firstSegment : null;
            
            var dfd = options.dfd = (options.walk ? null : options.dfd) || $.Deferred();
            if (_.isFunction(callback)) dfd.then(callback);
            
            var cb = function(view) {
                root._silent = false;
                if (_.isObject(view)) {
                    this._fetchedView(view, function() {
                        this.trigger('after:fetch', view);
                        this.spawn('after:fetch', view);
                        if (this.autoroute || options.updateRoute) this.updateRoute(true);
                        return dfd.resolveWith(this, [view]);
                    });
                } else {
                    this.updateRoute(true, true); // reset
                    return dfd.resolveWith(this, [view]);
                }
            }.bind(this);
            
            if (firstSegment instanceof Backbone.View) {
                var target = firstSegment;
            } else if (_.isString(firstSegment)) {
                var target = this._findView(pathSegments, options);
            }
            
            var fetchView = function(vw) {
                var name = (item && _.isFunction(item.name)) ? item.name() : options.name;
                name = name || ((vw && _.isFunction(vw.name)) ? vw.name() : null);
                this._fetchView(vw, name, options, function(view, opts) {
                    if (!view) return cb(); // no view, skip;
                    _.extend(options, opts || {}); // overrides, if any
                    
                    if (_.isString(options.anchor)) {
                        view.anchor(options.anchor);
                        delete options.anchor;
                    }
                    
                    if (view instanceof SlideView && _.has(options, 'route')) {
                        var opts = _.omit(options, 'route');
                        if (_.isNull(options.route)) {
                            var promise = this.fetch(view, opts, callback);
                        } else {
                            var route = [view].concat(options.route || []);
                            var promise = this.fetch(route, opts, callback);
                        }
                    } else if (vw !== view) {
                        segments = (view instanceof SlideView) ? _.rest(segments) : segments;
                        var route = [view].concat(segments);
                        var promise = this.fetch(route, options, callback);
                    } else if (view && _.isFunction(view.fetch) && segments.length > 0) {
                        var promise = view.fetch(segments, options, cb.bind(null, view));
                    } else {
                        var promise = cb(view);
                    }
                    
                    this.updateUI(); // force
                    if (promise) dfd.then(promise);
                }.bind(this));
            }.bind(this);
            
            if (target === false) {
                fetchView();
            } else {
                this.show(target || options.name, opts, fetchView);
            }
            
            return dfd;
        },
        
        fetchedView: function(view, callback) {
            _.defer(callback);
        },
        
        prefetch: function(path, options, callback) {
            if (_.isFunction(options)) callback = options, options = {};
            if (_.isNumber(options)) options = { speed: options };
            options = _.extend({ show: false }, options);
            
            if (options.parallel) {
                var paths = [].concat(path);
                options.delay = options.delay || 0; // force tick
                var deferreds = _.map(paths, function(path) {
                    return this.walk(path, options, callback);
                }.bind(this));
                return $.when.apply($, deferreds);
            } else {
                return this.walk(path, options, callback);
            }
        },
        
        walk: function(paths, options, callback) {
            paths = [].concat(paths);
            if (_.isFunction(options)) callback = options, options = {};
            if (_.isNumber(options)) options = { speed: options };
            
            if (_.isNumber(options.delay) && !callback) {
                callback = function(view, next) {
                    setTimeout(next, options.delay);
                }
            }
            
            options = _.extend({ paths: paths, walk: true }, options);
            callback = _.isFunction(callback) ? callback : null;
            var root = this.getRoot(), self = this;
            var path = paths.shift();
            var dfd = options.dfd = options.dfd || $.Deferred();
            
            dfd.then(root.fetch(path, options, function(view) {
                if (_.isFunction(callback)) {
                    callback(view, function() {
                        if (paths.length > 0) {
                            root.walk(paths, options, callback);
                        } else {
                            dfd.resolve();
                        }
                    });
                } else if (paths.length > 0) {
                    root.walk(paths, options);
                } else {
                    dfd.resolve();
                }
            }));
            
            return dfd;
        },
        
        display: function(pos, callback) {
            return this.show(pos, 0, callback);
        },
        
        autoplay: function(bool, speed) {
            if (bool && !this.swiper.params.autoplay) speed = speed || 1000;
            if (speed) this.setParams({ autoplay: speed });
            this.swiper[bool ? 'startAutoplay' : 'stopAutoplay']();
        },
        
        show: function(pos, options, callback) {
            if (_.isFunction(options)) callback = options, options = {};
            if (_.isNumber(options)) options = { speed: options };
            options = _.extend({}, options);
            if (options.offset) pos = this._normalizePosition(pos || 0, options.offset);
            var dfd = $.Deferred();
            var view = this.get(pos || 0, dfd);
            if (_.isFunction(callback)) dfd.then(callback.bind(null, view));
            if (options.navigate && !options._noop) {
                var method = options.navigate === ':next' ? 'swipeNext' : 'swipePrev';
                this.once('slide:change:end', dfd.resolve.bind(null, view));
                this.swiper[method](true);
                return dfd;
            } else if (view && !options._noop) {
                this.once('slide:change:end', dfd.resolve.bind(null, view));
                var multiple = this.swiper.params.slidesPerView === 'auto'; 
                multiple = multiple || this.swiper.params.slidesPerView > 1;
                var loops = this.swiper.params.loop;
                var index = this._normalizeIndex(view.index());
                var force = options.force || this.swiper._initialized;
                this.swiper._initialized = false;
                
                if (!force && !loops && (index === this.swiper.activeIndex || (multiple && view.isVisible()))) {
                    this.updateRoute(true);
                    this.trigger('slide:change:end', this.swiper);
                } else {
                    var speed = _.has(options, 'speed') ? options.speed : this.swiper.params.speed;
                    if (speed === 0) this.swiper.activeIndex = index; // force immediately
                    this.swiper.swipeTo(index, options.speed, options.runCallbacks);
                    setTimeout(this.trigger.bind(this, 'slide:change:end', this.swiper), speed + 100); // force
                }
                return dfd;
            } else {
                return dfd.resolve().promise();
            }
        },
        
        prev: function(options, callback) {
            return this.show(':prev', options, callback);
        },
        
        next: function(options, callback) {
            return this.show(':next', options, callback);
        },
        
        views: function(filter) {
            if (filter === ':visible') filter = function(v) { return v.isVisible(); };
            var chain = _.chain(this.swiper ? this.swiper.slides : []).pluck('view').compact();
            return _.isFunction(filter) ? chain.filter(filter).value() : chain.value();
        },
        
        get: function(pos, callback) {
            if (_.isObject(callback) && callback.promise) {
                var dfd = callback, options = arguments[2];
                callback = null;
            }
            if (_.isNull(pos) || !this.swiper) return;
            
            var offset = _.isNumber(this.swiper.params.slidesPerView) ? 
                this.swiper.params.slidesPerView : 1;
            
            if (pos instanceof Backbone.Model) pos = 'cid:' + pos.cid;
            if (pos instanceof Marionette.View) {
                var slide = _.find(this.swiper.slides, function(slide) {
                    return slide.view === pos;
                });
            } else if (_.isArray(pos) || (_.isString(pos) && pos.indexOf('/') === 0)) {  
                var segments = _.segments(pos);
                var path = [].concat(segments);
                var view = this.get(segments.shift());
                if (!view && _.isString(path[0])) view = this._findView(path, {});
                if (view instanceof Backbone.View 
                    && _.isFunction(view.get) && segments.length > 0) {   
                    view = view.get(segments, callback);
                    if (view && dfd && _.isFunction(view.container.show) 
                        && view.container !== this) {
                        dfd.then(function() {
                            return view.container.show(segments, options); 
                        });
                    }
                }
                if (_.isFunction(callback)) callback(view);
                return view;
            } else if (_.isString(pos) && pos.indexOf('::') === 0) {
                var container = this.getRoot().activeContainer || this;
                return container.get(pos.substr(1), callback);
            } else if (arguments.length === 0 || pos === ':active' || pos === true) {
                var slide = this.swiper.activeSlide() || this.swiper.slides[0];
            } else if (pos === ':prev') {
                var active = this.swiper.activeIndex || 0;
                var index = active - offset;
                if (index < 0) {
                    if (!this.swiper.params.loop) return;
                    index = 0;
                }
                var slide = this.swiper.getSlide(index);
            } else if (pos === ':next') {
                var length = this.swiper.slides.length;
                var active = this.swiper.activeIndex || 0;
                var index = active + offset;
                if (index >= length) {
                    if (!this.swiper.params.loop) return;
                    index = length - 1;
                }
                var slide = this.swiper.getSlide(index);
            } else if (pos === ':first') {
                var slide = this.swiper.getFirstSlide();
            } else if (pos === ':last') {
                var slide = this.swiper.getLastSlide();
            } else if (pos === ':root' || pos === ':parent') {
                var view = pos === ':root' ? this.getRoot() : this.getParent();
                if (_.isFunction(callback)) callback(view);
                return view;
            } else if (_.isString(pos) && pos.indexOf('#') === 0) { // #id of slide or child node
                var slide = this.$el.find('> .swiper-wrapper > ' + pos).get(0);
                slide = slide || this.$el.find('> .swiper-wrapper > .swiper-slide:has(' + pos + ')').get(0);
                var nested = slide && slide.view instanceof SlideView && slide.view != this ? slide.view : null;
                if (nested && dfd) dfd.then(function() { return nested.show(pos, options); });
            } else if (_.isString(pos) && pos.indexOf('id:') === 0) { // #id
                var id = pos.substr(3);
                var slide = _.find(this.swiper.slides, function(slide) {
                    return slide.id === id;
                });
            } else if (_.isString(pos) && pos.indexOf('cid:') === 0) { // cid
                var cid = pos.substr(4);
                var slide = _.find(this.swiper.slides, function(slide) {
                    return slide.cid === cid || (slide.view && slide.view.cid === cid);
                });               
            } else if (_.isString(pos)) { // name
                var loops = this.swiper.params.loop;
                var slide = _.find(this.swiper.slides, function(slide) {
                    if (loops && $(slide).hasClass('swiper-slide-duplicate')) return false;
                    return slide.data('name') === pos;
                });
            } else if (_.isNumber(pos)) { // index
                var slide = this.swiper.getSlide(this._normalizePosition(pos));
            }
            if (slide && slide.view instanceof Marionette.View) {
                if (_.isFunction(callback)) callback(slide.view);
                return slide.view;
            }
        },
        
        hasPrev: function() {
            return false;
        },
        
        hasNext: function() {
            return false;
        },
        
        has: function(pos, skipInternal) {
            if (pos === ':prev') {
                if (this.swiper.params.loop) return true;
                var hasPrev = this._normalizeIndex(this.swiper.activeIndex) > 0;
                return hasPrev || (skipInternal !== true && !!this.hasPrev());
            } else if (pos === ':next') {
                if (this.swiper.params.loop) return true;
                var perView = _.isNumber(this.swiper.params.slidesPerView) ? 
                    this.swiper.params.slidesPerView : 1;
                var multiple = perView > 1 || this.swiper.params.slidesPerView === 'auto';
                var current = this._normalizeIndex(this.swiper.activeIndex);
                var total = this.swiper.slides.length;
                if (this.swiper.params.loop) total -= this.swiper.loopedSlides + 1;
                var hasNext = current < total - perView;
                if (this.swiper.params.freeMode || multiple) {
                    var last = this.swiper.getLastSlide();
                    var lastView = last && last.view;
                    hasNext = lastView && !lastView.isVisible();
                }
                return hasNext || (skipInternal !== true && !!this.hasNext());
            } else if (pos === ':first' || pos === ':last') {
                return this.swiper.slides.length > 0;
            }
            return this.get(pos) instanceof Backbone.View;
        },
        
        indexOf: function(pos) {
            if (arguments.length === 0 || pos === ':active') {
                return this.swiper.activeIndex;
            } else {
                var slide = this.get(pos);
                return slide ? slide.index() : -1;
            }
        },
        
        prepend: function(view, options, callback) {
            if (_.isArray(view)) {
                return _.map(view, function(vw) {
                    this.prepend(vw, options, callback);
                }.bind(this));
            }
            
            view = this.wrapView(view, options);
            this.triggerMethod('before:change', 'prepend', view);
            this.triggerMethod('before:prepend', view);
            this.swiper.prependSlide(view.el);
            view.render(); // force-render
            this.swiper.swipeTo(this.swiper.activeIndex + 1, 0, false); // fix index
            this.triggerMethod('after:prepend', view);
            this.triggerMethod('after:change', 'prepend', view);
            this._show('prepend', view, options, callback);
            return view;
        },
        
        append: function(view, options, callback) {
            if (_.isArray(view)) {
                return _.map(view, function(vw) {
                    this.append(vw, options, callback);
                }.bind(this));
            }
            view = this.wrapView(view, options);
            this.triggerMethod('before:change', 'append', view);
            this.triggerMethod('before:append', view);
            this.swiper.appendSlide(view.el);  
            view.render(); // force-render
            this.triggerMethod('after:append', view);
            this.triggerMethod('after:change', 'append', view);
            this._show('append', view, options, callback);
            return view;
        },
        
        insert: function(pos, view, options, callback) {
            var index = this._normalizePosition(pos, -1);
            
            if (_.isArray(view)) {
                return _.map(view, function(vw) {
                    this.insert(index + 1, vw, options, callback);
                }.bind(this));
            }
            
            view = this.wrapView(view, options);
            var events = (options || {}).events !== false;
            if (events) this.triggerMethod('before:change', 'insert', view);
            if (events) this.triggerMethod('before:insert', view, index);
            if (pos === 0) {
                this.swiper.prependSlide(view.el);
            } else {
                this.swiper.insertSlideAfter(index, view.el);
            }
            this.swiper.swipeTo(this.swiper.activeIndex + 1, 0, false);
            view.render(); // force-render
            if (events) this.triggerMethod('after:insert', view, pos);
            if (events) this.triggerMethod('after:change', 'insert', view);
            this._show('insert', view, options, callback);
            return view;
        },
        
        replace: function(pos, view, options, callback) {
            if (_.isFunction(options)) callback = options, options = {};
            options = _.extend({ animate: this.options.animate }, options);
            var exists = (view instanceof BaseSlideItemView && this.has(view));
            var replaced = this.get(pos); // the view to be replaced, if any
            if (replaced) { 
                pos = this._normalizePosition(replaced.index());
            }
            
            view = exists ? view : this.wrapView(view, options);
            
            var replace = function() {
                var opts = _.omit(options, 'speed', 'show', 'display');
                opts.events = false; // don't trigger insert event
                if (replaced) replaced.detach();
                this.insert(pos, exists ? view.detach() : view, opts);
            }.bind(this);
            
            this.triggerMethod('before:change', 'insert', view);
            this.triggerMethod('before:replace', view, pos);
            
            if (options.animate) {
                var wrapper = this.swiper.wrapper;
                var promise = this.animateElement(wrapper, replace);
                promise.then(function() {
                    this.triggerMethod('after:replace', view, pos);
                    this.triggerMethod('after:change', 'replace', view);
                    this._show('replace', view, options, callback);
                }.bind(this));
            } else {
                replace();
                this.triggerMethod('after:replace', view, pos);
                this.triggerMethod('after:change', 'replace', view);
                this._show('replace', view, options, callback);
            }
            return view;
        },
        
        detach: function() {
            if (_.isFunction(this.el.remove)) {
                this.el.remove();
            }
            if (this.container) {
                this.container.stopListening(this);
            }
            return this;
        },
        
        remove: function(pos, options, callback) {
            if (arguments.length === 0) { // default backbone behavior
                return Marionette.ItemView.prototype.remove.call(this);
            } else if (!this.swiper) {
                if (_.isFunction(callback)) callback();
                return;
            }
            
            if (_.isFunction(options)) callback = options, options = {};
            options = _.extend({ animate: this.options.animate }, options);
            var activeIndex = this.swiper.activeIndex || 0;
            var isFiltered = _.isFunction(pos);
            var hasCallback = _.isFunction(callback);
            
            var count = 0;
            
            var remove = function(view, idx) {
                this.triggerMethod('before:change', 'remove', view);
                this.triggerMethod('before:remove', view);
                var index = view.index();
                view[options.detach ? 'detach' : 'destroy']();
                if (options.force !== true) {
                    if (index < activeIndex && activeIndex > 0) {
                        this.swiper.swipeTo(activeIndex - 1, 0, false);
                    } else {
                        this.swiper.swipeTo(activeIndex, 0, false);
                    }
                }
                this.triggerMethod('after:remove', view);
                this.triggerMethod('after:change', 'remove', view);
                if (hasCallback && isFiltered && idx === count - 1) {
                    callback(view); // final iteration
                } else if (hasCallback && !isFiltered) {
                    callback(view); // each iteration
                }
            }.bind(this);
            
            var handler = function(view, idx) {
                var isActive = view.el.isActive();
                if (isActive && options.animate) {
                    var wrapper = this.swiper.wrapper;
                    this.animateElement(wrapper, function() {
                        remove(view, idx);
                    });
                } else if (isActive) {
                    this.show(':prev', function() {
                        remove(view, idx);
                    });
                } else {
                    remove(view, idx);
                }
            }.bind(this);
            
            if (isFiltered) { // filter function
                var views = this.views(pos);
                if (_.isEmpty(views) && _.isFunction(callback)) {
                     callback();
                } else {
                    count = views.length;
                    _.each(views, handler);
                }
                return views;
            } else {
                var view = this.get(pos);
                if (view) {
                    handler(view);
                } else if (_.isFunction(callback)) {
                    callback();
                }
                return view;
            }
        },
        
        clear: function() {
            if (!this.swiper) return;
            var ret = this.remove(function() { return true; }, { animate: false });
            if (ret.length > 0) this.swiper.reInit();
            this.swiper._initialized = true;
            this.swiper.activeIndex = 0;
            this.swiper.previousIndex = null;
            this.previousView = null;
            return ret;
        },
        
        move: function(pos1, pos2, options, callback) {
            if (_.isFunction(options)) callback = options, options = {};
            var index1 = this._normalizePosition(pos1); 
            var index2 = this._normalizePosition(pos2);
            var view2 = this.get(index2);
            this.remove(index1, function(view1) {
                if (view1 && view2) {
                    var index = index1 > index2 ? index1 + 1: index1;
                    this.insert(index2, view1, options, function(view) {
                        if (_.isFunction(callback)) callback(view, view2);
                    });
                }
            }.bind(this));
        },
        
        swap: function(pos1, pos2, options, callback) {
            if (_.isFunction(options)) callback = options, options = {};
            var index1 = this._normalizePosition(pos1); 
            var index2 = this._normalizePosition(pos2);
            this.move(pos1, pos2, options, function(view1, view2) {
                var index = index1 > index2 ? index1 + 1: index1;
                this.insert(index, view2, options, function(view) {
                    if (_.isFunction(callback)) callback(view1, view);
                });
            }.bind(this));
        },
        
        loadDone: function(data, status, xhr) {
            return this.setContent(data);
        },
        
        loadFail: function(xhr, status, msg) {
            this.clear();
        },
        
        setContent: function(content, callback) {
            var html = this.extractContent(content, this.options);
            var $slides = html.find('.swiper-slide');
            $slides = $slides.not('.swiper-slide .swiper-wrapper .swiper-slide');
            if ($slides.length > 0 && this.swiper) {
                var wrapper = $(this.swiper.wrapper);
                var previousView = this.get(':active');
                var previous = previousView ? previousView.name() : null;
                this.once('render', this.displayContent.bind(this, previous));
                if (this.options.animate) {
                    var promise = this.animateElement(wrapper, function() {
                        this.clear();
                        this.updateWrapper(wrapper, $slides);
                        this.render();
                    }.bind(this));
                    if (_.isFunction(callback)) promise.then(callback);
                    return promise;
                } else {
                    this.trigger('before:change:content');
                    this.clear();
                    this.updateWrapper(wrapper, $slides);
                    var ret = this.render();
                    if (_.isFunction(callback)) callback(wrapper, $slides);
                    this.trigger('after:change:content');
                    return ret;
                }
            } else {
                var dfd = $.Deferred();
                if (_.isFunction(callback)) dfd.then(callback);
                return dfd.resolve().promise();
            }
        },
        
        extractContent: extractContent,
        
        displayContent: function(previous) {
            this.display(0); // default to first item
        },
        
        updateWrapper: function(wrapper, html) {
            if (_.isObject(this.delegate) && _.isFunction(this.delegate.updateWrapper)) {
                this.delegate.updateWrapper(wrapper, html);
            } else {
                wrapper.html(html);
            }
        },
        
        animateElement: function(elem, callback) {
            if (_.isFunction(elem)) callback = elem, elem = null;
            if (!this.swiper) return;
            elem = elem || this.swiper.wrapper;
            return this.animateElementBefore(elem, function() {
                this.triggerMethod('before:change:content');
                if (_.isFunction(callback)) callback();
                return this.animateElementAfter(elem, function() {
                    this.triggerMethod('after:change:content');
                }.bind(this));
            }.bind(this));
        },
        
        animateElementBefore: function(elem, callback) {
            if (_.isFunction(elem)) callback = elem, elem = null;
            elem = elem || this.swiper.wrapper;
            var animBefore = this.options.animateBefore || { opacity: 0 };
            return $(elem)[animMethod](animBefore, this.options.animate || 'slow', function() {
                this.triggerMethod('before:animate', elem);
                if (_.isFunction(callback)) callback();
            }.bind(this)).promise();
        },
        
        animateElementAfter: function(elem, callback) {
            if (_.isFunction(elem)) callback = elem, elem = null;
            elem = elem || this.swiper.wrapper;
            var animBefore = this.options.animateBefore || { opacity: 0 };
            var animAfter = this.options.animateAfter || { opacity: 1 };
            if ($(elem).is(':hidden')) $(elem).css(animBefore).removeClass('hidden');
            return $(elem).show()[animMethod](animAfter, this.options.animate || 'slow', function() {
                _.defer(this.updateFocus.bind(this)); // trigger view update
                this.triggerMethod('after:animate', elem); 
                if (_.isFunction(callback)) callback();
            }.bind(this)).promise();
        },
        
        render: function() {
            this.isDestroyed = false;
            
            this.spawn('before:render');
            this.triggerMethod('before:render');
            this.triggerMethod("before:render", this);
            
            if (this.swiper) {
                this.renderTemplate();
                this.refresh();
                this.loadImages();
                this.updateRoute();
                _.defer(function() {
                    this.updateUI();
                    this.performTouchEnd();
                }.bind(this));
            } else if (this.el) {
                this.trigger('init', this);
                this.spawn('init', this);
                
                this.renderTemplate(true);
                
                var options = _.extend({}, this.config);
                var paginationId = _.uniqueId('pg');
                var root = this.getRoot();
                
                options.onSlideClick = this.triggerMethod.bind(this, 'slide:click');
                options.onSlideTouch = this.triggerMethod.bind(this, 'slide:touch');
                options.marionette = { view: this }; // pseudo-plugin
                options.watchActiveIndex = true;
                options.optimize = _.result(this, 'optimize');
                
                if (options.progress) {
                    options.onProgressChange = this.triggerMethod.bind(this, 'progress:change');
                }
                
                if (this.$el.is('.vertical')) options.mode = options.mode || 'vertical';
                if (this.options.name) this.$el.data('name', this.options.name);
                
                this.$el.addClass(this.className); // force classes
                this.$el.removeClass('horizontal vertical');
                this.$el.addClass(options.mode === 'vertical' ? 'vertical' : 'horizontal');
                
                if (!isBuggyChrome) this.$el.addClass('force-transforms'); // force gpu rendering
                
                if (this.$el.children('.swiper-wrapper').length == 0) {
                    this.$el.wrapInner('<div>').children(':first').addClass('swiper-wrapper');
                }
                
                if (_.isObject(options.scrollbar) && !_.isString(options.scrollbar.container) 
                    && this.$el.children('.swiper-scrollbar').length === 0) {
                    var scrollbar = $('<div class="swiper-scrollbar">');
                    this.$el.append(scrollbar);
                    options.scrollbar.container = scrollbar[0];
                    options.onScrollbarDrag = this.triggerMethod.bind(this, 'scrollbar:drag');
                }
                
                if (Marionette.getOption(this, 'navigation')) {
                    var navigation = Marionette.getOption(this, 'navigation');
                    var nav = (navigation instanceof HTMLElement) ? $(navigation) : null;
                    nav = nav || (_.isString(navigation) ? $(navigation) : null);
                    nav = $(nav || this.$el);
                    
                    var element = function(direction) {
                        var elem = nav.find('[data-nav="' + direction + '"]')[0]; // existing element from DOM
                        if (elem) {
                            elem = this.navigationElement(direction, elem);
                        } else {
                            elem = this.navigationElement(direction, this._navigationElement(direction)[0]);
                        }
                        if (elem) $(elem).appendTo(this.$el);
                    }.bind(this);
                    
                    var navigationV = Marionette.getOption(this, 'navigationV');
                    var navigationH = Marionette.getOption(this, 'navigationH');
                    if (navigationV !== false || options.mode === 'vertical') element('up');
                    if (navigationV !== false || options.mode === 'vertical') element('down');
                    if (navigationH !== false || options.mode !== 'vertical') element('left');
                    if (navigationH !== false || options.mode !== 'vertical') element('right');
                    
                    this.triggerMethod('create:navigation', nav);
                }
                
                var createPagination = options.pagination && !options.pagination.nodeType;
                options.paginationClickable = options.pagination && options.paginationClickable !== false;
                
                if (_.isString(options.pagination) && options.pagination.indexOf('#') === 0) {
                    paginationId = options.pagination.substr(1);
                    var pagination = $(options.pagination);
                    if (pagination.is('*')) {
                        pagination.addClass('swiper-pagination');
                        options.pagination = pagination[0];
                        createPagination = false;
                    }
                } else if (options.pagination === true) {
                    var pagination = this.$el.children('.swiper-pagination');
                    if (pagination.is('*')) {
                        pagination.addClass('swiper-pagination');
                        options.pagination = pagination[0];
                        createPagination = false;
                    }
                }
                
                if (createPagination) {
                    options.pagination = '#' + paginationId;
                    if (this.$el.children(options.pagination).length == 0) {
                        var pagination = $('<div/>').addClass('swiper-pagination');
                        pagination.attr('id', paginationId);
                        this.$el.append(pagination);
                        options.pagination = pagination[0];
                        this.triggerMethod('create:pagination', pagination);
                    }
                }
                
                this.triggerMethod('before:render:initial', options);
                
                var swiper = this.swiper = new Swiper(this.el, options);
                
                swiper.delegate = this;
                
                _.each(swiper.slides, function(slide, idx) {
                    slide.view = this.wrapView(slide, { init: true, index: idx });
                    slide.view.render();
                }.bind(this));
                
                swiper.addCallback('Init', this.triggerMethod.bind(this, 'swiper:init'));
                
                swiper.addCallback('TouchStart', this.triggerMethod.bind(this, 'touch:start'));
                swiper.addCallback('TouchMove',  this.triggerMethod.bind(this, 'touch:move'));
                swiper.addCallback('TouchEnd',   this.triggerMethod.bind(this, 'touch:end'));
                swiper.addCallback('SlideReset', this.triggerMethod.bind(this, 'slide:reset'));
                swiper.addCallback('SlideTouch', this.triggerMethod.bind(this, 'slide:touch'));
                
                swiper.addCallback('SlideChangeStart', this.triggerMethod.bind(this, 'slide:change:start'));
                swiper.addCallback('SlideChangeEnd',   this.triggerMethod.bind(this, 'slide:change:end'));
                
                swiper.addCallback('ResistanceBefore', this.triggerMethod.bind(this, 'resistance:before'));
                swiper.addCallback('ResistanceAfter',  this.triggerMethod.bind(this, 'resistance:after'));
                
                swiper.addCallback('BeforeSlide', this.triggerMethod.bind(this, 'slide:start')); // can cancel
                swiper.addCallback('SetWrapperTransition', this.triggerMethod.bind(this, 'slide:end'));
                
                this.loadImages();
                
                if (options.progress) {
                    swiper.addCallback('TouchStart', this.triggerMethod.bind(this, 'progress:start'));
                    swiper.addCallback('SetWrapperTransition', this.triggerMethod.bind(this, 'progress:update'));
                    this.triggerMethod('progress:init', swiper);
                    this.on('change', this.triggerMethod.bind(this, 'progress:change', swiper));
                }
                
                if (this.model instanceof Backbone.Model) {
                    this.listenTo(this.model, 'change', this.render);
                }
                
                this.on('change render:initial', this.updateUI, this);
                this.on('after:change:content', this.updateUI, this);
                
                this.on('view:scroll:start', this.onScrollStart);
                this.on('view:scroll:end', this.onScrollEnd);
                
                if (_.isObject(this.delegate) && _.isFunction(this.delegate.viewReady)) {
                    this.once('after:change:content', this.delegate.viewReady.bind(this.delegate, this));
                }
                
                this.triggerMethod('render:initial');
                
                if (swiper.slides.length > 0) { // initial state
                    this.trigger('after:change:content');
                } else {
                    this.updateUI();
                    this._onReady();
                }
                
                this._forceRefresh = !this.$el.is(':visible');
                
                this.updateFocus(); // force
            }
            
            this.bindUIElements();
            
            this.triggerMethod('render');
            this.triggerMethod('render', this);
            this.spawn('render');
        },
        
        renderTemplate: function(initial) {
            var data = this.serializeData();
            data = this.mixinTemplateHelpers(data);
            
            var containerTemplate = Marionette.getOption(this, 'containerTemplate');
            if (initial && containerTemplate) {
                var html = Marionette.Renderer.render(containerTemplate, data);
                this.$el.html(html);
            }
            
            if (this.$el.children('.swiper-wrapper').length == 0) {
                this.$el.wrapInner('<div>').children(':first').addClass('swiper-wrapper');
            }
            
            var template = this.getTemplate();
            if (template) {
                var html = Marionette.Renderer.render(template, data);
                var wrapper = this.$el.children('.swiper-wrapper').eq(0);
                wrapper.html(html);
            }
            
            this.bindUIElements();
        },
        
        loadImages: function(callback) {
            callback = callback || this.triggerMethod.bind(this, 'images:ready');
            var nested = this.$el.find('.swiper-slide .swiper-slide img');
            var images = this.$el.find('.swiper-slide img').not(nested);
            var loaded = 0;
            images.each(function() {
                var img = $(this);
                if (img.data('imgloaded')) return;
                var image = new Image();
                $(image).one('load', function() {
                    loaded++;
                    img.data('imgloaded', true);
                    if (loaded === images.length) callback();
                });
                image.src = $(this).attr('src');
            });
        },
            
        onProgressStart: function() {
            _.each(this.swiper.slides, function(slide) {
                this.swiper.setTransition(slide, 0);
            }.bind(this));
        },
        
        onProgressUpdate: function() {
            _.each(this.swiper.slides, function(slide) {
                this.swiper.setTransition(slide, this.swiper.params.speed);
            }.bind(this));
        },
        
        refresh: function() {
            var changed = false || this._forceRefresh;
            delete this._forceRefresh;
            if (this.swiper) {
                this.swiper.slides = [];
                $(this.swiper.wrapper).children('*').each(function(idx, slide) {
                    if (changed || !slide.view) changed = true;
                    slide.view = slide.view || this.wrapView(slide, { init: true, index: idx });
                    slide.view.refresh();
                    this.swiper.slides[idx] = slide;
                }.bind(this));
                if (changed) this.swiper.reInit();
            }
        },
        
        onResize: function() {
            _.invoke(this.views(':active'), 'onResize');
            this.performTouchEnd();
        },
        
        enable: function() {
            this.disabled = false;
            this.$el.removeClass('disabled');
            this.setFlags({ keyboardControl: true, mousewheelControl: true });
        },
        
        disable: function() {
            this.disabled = true;
            this.$el.addClass('disabled');
            this.setFlags({ keyboardControl: false, mousewheelControl: false });
        },
        
        setFlags: function(options) {
            if (_.isObject(options)) {
                if (!this.config.onlyExternal && _.has(options, 'onlyExternal')) {
                    var onlyExternal = !!options.onlyExternal;
                    if (!this._canSwipe()) onlyExternal = true;
                    this.swiper.params.onlyExternal = onlyExternal;
                }
                if (this.config.keyboardControl && _.has(options, 'keyboardControl')) {
                    this.swiper.params.keyboardControl = !!options.keyboardControl;
                }
                if (this.config.mousewheelControl && _.has(options, 'mousewheelControl')) {
                    var forceAxis = this.config.mousewheelControlForceToAxis;
                    var mousewheel = !!(options.mousewheelControl || (forceAxis && !options.onlyExternal));
                    this.swiper.params.mousewheelControl = mousewheel;
                }
            }
            return _.pick(this.swiper.params, 'onlyExternal', 'keyboardControl', 'mousewheelControl');
        },
        
        viewFocus: function(message) {
            var data = message.data || {};
            var root = this.getRoot();
            var source = message.source;
            var view = (source instanceof SlideView) ? source : this;
            var active = view.get(':active') || view.get(':first');
            var parent = view.getParent();
            var container = parent || this;
            var direction = container.isHorizontal() ? 'horizontal' : 'vertical';
            
            if (!active) {
                root.activeContainer = view; // keep reference
                view.updateUI();
                return;
            } else if (!this.isActive()) {
                return;
            }
            
            root.activeContainer = view; // keep reference
            
            active.enable();
            active.updateUI();
            
            var isHorizontal = view.isHorizontal();
            var scrollable = active.isScrollable(direction);
            var pullable = Marionette.getOption(this, 'pull') === true;
            var mousewheel = !scrollable;
            var isFirst = view.isFirst();
            var isLast = view.isLast();
            var onlyExt = scrollable && this.swiper.params.slidesPerView === 1;
            
            var views = view.views();
            if (parent) views = views.concat(parent.views());
            
            _.each(views, function(child) {
                child.setFlags({ keyboardControl: false });
            });
            
            if (parent && parent.isHorizontal() && !isHorizontal) { // H-V
                // console.log("MODE", 'H-V', active.name(), view.name(), parent.name());
                var params = view.setFlags({ onlyExternal: onlyExt, mousewheelControl: mousewheel });
                parent.setFlags({ onlyExternal: false, mousewheelControl: !params.mousewheelControl });
                view.setFlags({ keyboardControl: true });
                parent.setFlags({ keyboardControl: true });
            } else if (parent && !parent.isHorizontal() && isHorizontal) { // V-H
                // console.log("MODE", 'V-H', active.name(), view.name(), parent.name());
                var params = view.setFlags({ onlyExternal: false, mousewheelControl: mousewheel });
                parent.setFlags({ onlyExternal: onlyExt, mousewheelControl: !params.mousewheelControl });
                view.setFlags({ keyboardControl: true });
                parent.setFlags({ keyboardControl: true });         
            } else if (parent && parent.isHorizontal() && isHorizontal) { // H-H
                // console.log("MODE", 'H-H', active.name(), view.name(), parent.name());
                var ext = this.options.skipSwipe ? !isFirst : true;
                if (pullable) ext = !isFirst && !isLast;
                view.setFlags({ onlyExternal: false, mousewheelControl: true });
                parent.setFlags({ onlyExternal: ext, mousewheelControl: isFirst });
                view.setFlags({ keyboardControl: true });
                parent.setFlags({ keyboardControl: isFirst });
            } else if (parent && !parent.isHorizontal() && !isHorizontal) { // V-V
                // console.log("MODE", 'V-V', active.name(), view.name(), parent.name());
                var ext = this.options.skipSwipe ? !isFirst : true;
                if (pullable) ext = !isFirst && !isLast;
                view.setFlags({ onlyExternal: false, mousewheelControl: true });
                parent.setFlags({ onlyExternal: ext, mousewheelControl: isFirst });
                view.setFlags({ keyboardControl: true });
                parent.setFlags({ keyboardControl: isFirst });
            } else if (isHorizontal) { // H
                // console.log("MODE", 'H', active.name(), view.name())
                view.setFlags({ onlyExternal: false, mousewheelControl: mousewheel });
                view.setFlags({ keyboardControl: true });
            } else if (!isHorizontal) { // V
                // console.log("MODE", 'V', active.name(), view.name())
                view.setFlags({ onlyExternal: onlyExt, mousewheelControl: mousewheel });
                view.setFlags({ keyboardControl: true });
            }
            
            root.$el[!isHorizontal ? 'addClass' : 'removeClass']('swiper-vertical');
            
            var segments = [];
            if (view !== root) segments.push(view.name());
            segments.push(active.name());
            
            root.path = _.compact(segments);
            if (this !== root) this.path = segments.slice(-1);
            
            if (!data.force) {
                view.updateUI();
                view.updateRoute();
            }
            
            this.updateVisibility();
            
            this.triggerMethod('view:focus', data.src, data.view, data.previous);
            if (data.view && data.view.enable) data.view.enable();
            if (this !== root) root.triggerMethod('view:focus', data.src, data.view, data.previous);
        },
        
        viewBlur: function(message) {
            var root = this.getRoot();
            var data = message.data; // args: 
            this.triggerMethod('view:blur', data.src, data.view, data.active);
            if (data.view && data.view.disable) data.view.disable();
            if (this !== root) root.triggerMethod('view:blur', data.src, data.view, data.active);
        },
        
        updateFocus: function() { 
            var active = this.get(':active');
            if (active) {
                active.spawn('view:focus', { src: this, view: active, previous: this.previousView });
                active.triggerMethod('focus', this, active, this.previousView);
            }
            var previousView = this.previousView || this.get(this.swiper.previousIndex) || this.get(':first');
            if (previousView && previousView !== active) {
                this.previousView = previousView;
                this.previousView.spawn('view:blur', { src: this, view: this.previousView, active: active });
                this.previousView.triggerMethod('blur', this, this.previousView, active);
            }
        },
        
        updateVisibility: function() {
            if (_.result(this, 'optimize')) {
                var prev = this.get(':prev');
                var next = this.get(':next');
                _.each(this.views(), function(vw) {
                    vw.setVisibility(vw.isActive() || vw.isVisible() || vw === prev || vw === next);
                });
            }
        },
        
        slideViewOptions: function(slide, options) {
            return options;
        },
        
        slideViewConfig: function(slide, options) {
            return options;
        },
        
        slideViewClass: function(slide, options) {
            var childView = _.isObject(options) ? options.slideView : null;
            return childView || Marionette.getOption(this, 'slideView') || this.constructor;
        },
        
        slideViewInstance: function(slide, options) {
            var view;
            options = _.extend({ el: slide }, this.slideViewOptions(slide, {}), options);
            var childView = this.slideViewClass(slide, options);
            var defaults = { nested: true, mode: $(slide).is('.vertical') ? 'vertical' : 'horizontal' };
            options.config = _.extend(this.slideViewConfig(slide, defaults), options.config);
            if (_.isObject(this.delegate) && _.isFunction(this.delegate.slideViewInstance)) {
                view = this.delegate.slideViewInstance(this, childView, slide, options);
            } else if (_.isFunction(this.constructor.slideViewInstance)) {
                view = this.constructor.slideViewInstance(this, childView, slide, options);
            }
            return view || (new childView(options));
        },
        
        childViewOptions: function(slide, options) {
            return options;
        },
        
        childViewClass: function(slide, options) {
            var childView = _.isObject(options) ? options.childView : null;
            return childView || Marionette.getOption(this, 'childView') || BaseSlideItemView;
        },
        
        childViewInstance: function(slide, options) {
            var view;
            var childView = this.childViewClass(slide, options);
            if (_.isObject(this.delegate) && _.isFunction(this.delegate.childViewInstance)) {
                view = this.delegate.childViewInstance(this, childView, slide, options);
            } else if (_.isFunction(this.constructor.childViewInstance)) {
                view = this.constructor.childViewInstance(this, childView, slide, options);
            }
            return view || (new childView(options));
        },
        
        viewInstance: function(slide, options) {
            var view;
            options = _.extend({ el: slide }, this.childViewOptions(slide, {}), options);
            if ($(slide).is('.swiper-container') || options.nested) {
                view = this.slideViewInstance(slide, options);
            } else {
                view = this.childViewInstance(slide, options);
            }
            view.container = this; // reference to slide view (1)
            this.triggerMethod('view:instance', view, options);
            return view;
        },
        
        createView: function(content, options) {
            var html = this.extractContent(content, options);
            if (html.length > 0) {
                return this.viewInstance(html[0], options);
            }
        },
        
        wrapView: function(view, options) {
            var root = this.getRoot();
            var $slide;
            if (_.isBoolean(options)) options = {};
            if (view instanceof Backbone.Model) {
                options = _.extend({ model: view }, options);
                view = null;
            }
            if (_.isString(options)) options = { name: options };
            options = _.extend({ index: 0 }, options);
            if (!this.swiper) this.render();
            
            if ((view instanceof BaseSlideItemView) || (view instanceof SlideView)) {
                if (!(view.el && view.el.nodeType)) view.render();
                var slide = view.el;
                if (!_.isObject(slide.swiperSlideDataStorage)) {
                    this.swiper._extendSwiperSlide(slide);
                }
                if (_.isFunction(view.name)) options.name = options.name || view.name();
                options.name = options.name || view.$el.attr('data-name');
                options.type = options.type || view.$el.data('type');
            } else if (view instanceof Backbone.View) {
                var nested = options.nestedView = view;
                options.scrollable = view.$el.is('.scrollable');
                options.name = options.name || view.$el.attr('data-name');
                options.type = options.type || view.slideType || view.$el.data('type');
                var slide = this.swiper.createSlide('<div class="nested"></div>');
                var $nested = $(slide).children('.nested');
                if (nested.id) $nested.attr('id', view.id);
                if (nested.className) $nested.addClass(view.className);
                var view = this.viewInstance(slide, options);
            } else {
                if (view instanceof HTMLElement) {
                    var slide = view; // already a valid slide element
                    if (!_.isObject(slide.swiperSlideDataStorage)) {
                        this.swiper._extendSwiperSlide(slide);
                    }
                } else {
                    var html = view instanceof jQuery ? view.html() : null;
                    html = html || (view instanceof HTMLElement ? view : null);
                    html = html || ($.isPlainObject(view) ? view.content : null); // plain object
                    html = html || ((_.isString(view) || _.isNumber(view)) ? view : '');
                    var slide = this.swiper.createSlide(html);
                }
                
                if ($.isPlainObject(view)) {
                    _.extend(options, _.omit(view, 'content'));
                }
                
                $slide = $(slide);
                if ($slide.children('.swiper-wrapper').length > 0) {
                    options.nested = true;
                } else if ($slide.children('.scrollable').length > 0) {
                    options.scrollable = true;
                }
                
                options.name = options.name || $slide.data('name');
                options.type = options.type || $slide.data('type');
                var view = this.viewInstance(slide, options);
            }
            
            if (options.model instanceof Backbone.Model) {
                view.model = options.model;
                options.name = view.model.id;
                slide.cid = view.model.cid;
            } else {
                slide.cid = view.cid;
            }
            
            $slide = $slide || $(slide);
            
            $slide.addClass('swiper-slide'); // always apply class
            
            if (options.id) slide.id = options.id;
            if (options.name) slide.data('name', options.name);
            if (options.label) slide.data('label', options.label);
            if (options.tag) slide.data('tag', options.tag);
            if (options.className) $slide.addClass(options.className);
            if (options.scrollable) $slide.addClass('scrollable');
            if (view.className) $slide.addClass(view.className);
            
            if (this.options.viewEvents) {
                this.listenTo(view, 'all', function(name) {
                    var args = ['view:' + name].concat(_.rest(arguments));
                    view.container.trigger.apply(view.container, args);
                });
            }
            
            view.container = this; // reference to slide view (2)
            slide.view = view;
            
            this.triggerMethod('view:register', view, options);
            if (this !== root) root.triggerMethod('view:register', view, options);
            
            view.trigger('parent:register', this, options);
            
            return view;
        },
        
        triggerMethod: function(name) {
            var ret = Marionette.View.prototype.triggerMethod.apply(this, arguments);
            if (this.container && name.indexOf('view:') === 0) {
                this.container.triggerMethod.apply(this.container, arguments);
            }
            if (_.isObject(this.delegate) 
                && _.isFunction(this.delegate.trigger)) {
                var args = [name].concat(this).concat(_.rest(arguments));
                this.delegate.trigger.apply(this.delegate, args);
            }
            return ret;
        },
        
        triggerViews: function(name) {
            _.invoke.apply(_, [this.views(), 'triggerMethod'].concat(_.toArray(arguments)));
        },
        
        onDestroy: function() {
            this.$el.find('img').attr('src', tinyGIF);
            this.$el.data('view', null);
            this.swiper.destroy();
            delete this.swiper;
        },
        
        navigationElement: function(direction, elem) {
            if (elem instanceof HTMLElement) {
                this._navigation[direction] = elem;
            }
            return this._navigation[direction]; // DOM element
        },
        
        _navigationElement: function(direction) {
            var elem = this.navigationElement(direction);
            elem = elem || '<div><span class="fa fa-chevron-' + direction + '"></span></div>';
            return $(elem).addClass('swiper-nav disabled').addClass('nav-' + direction).attr('data-nav', direction);
        },
        
        _onReady: function() {
            setTimeout(this.triggerMethod.bind(this, 'ready'), 0);
        },
        
        _show: function(action, view, options, callback) {
            var cb = function(vw) {
                this.triggerMethod('after:' + action + ':show', vw);
                if (_.isFunction(callback)) callback(vw);
            }.bind(this);
            
            if (options === true || (options || {}).show === true) {
                this.triggerMethod('before:' + action + ':show', view);
                this.show(view, cb);
            } else if (_.isNumber(options)) {
                this.triggerMethod('before:' + action + ':show', view);
                this.show(view, options, cb);
            } else if (_.isNumber((options || {}).show)) {
                this.triggerMethod('before:' + action + ':show', view);
                this.show(view, options.show, cb);
            } else {
                this.triggerMethod('before:' + action + ':show', view);
                cb(view);
                this.updateFocus();
            }
        },
        
        _parsePath: function(path, options) {
            if (_.isFunction(this.options.parsePath)) {
                return this.options.parsePath.call(this, path, options || {});
            } else if (_.isObject(this.delegate) && _.isFunction(this.delegate.parsePath)) {
                return this.delegate.parsePath.call(this.delegate, this, path, options || {});
            } else if (_.isFunction(this.parsePath)) {
                return this.parsePath.call(this, path, options || {});
            } else {
                return _.segments(path);
            }
        },
        
        _loadView: function(view, name, options, callback) {
            view.load(options.path).done(function() {
                callback(view);
            });
        },
        
        _findView: function(path, options) {
            if (_.isFunction(this.options.findView)) {
                return this.options.findView.call(this, path, options || {});
            } else if (_.isObject(this.delegate) && _.isFunction(this.delegate.findView)) {
                return this.delegate.findView.call(this.delegate, this, path, options || {});
            } else if (_.isFunction(this.findView)) {
                return this.findView.call(this, path, options || {});
            }
        },
        
        _fetchView: function(view, name, options, callback) {
            options = _.extend({}, options);
            var cb = function(fallback, overrides) {
                this.triggerMethod('view:fetch', fallback, overrides);
                callback(fallback, overrides);
            }.bind(this);
            
            view = view instanceof Backbone.View ? view : null;
            
            if (options.load && view instanceof Backbone.View && _.isFunction(view.load)) {
                this._loadView(view, name, options, callback);
            } else if (_.isFunction(this.options.fetchView)) {
                this.options.fetchView.call(this, view, name, options, cb);
            } else if (_.isObject(this.delegate) && _.isFunction(this.delegate.fetchView)) {
                this.delegate.fetchView.call(this.delegate, this, view, name, options, cb);
            } else if (_.isFunction(this.fetchView)) {
                this.fetchView.call(this, view, name, options, cb);
            } else {
                callback(view);
            }
        },
        
        _normalizePosition: function(pos, offset) {
            if (!this.swiper) return 0;
            offset = offset || 0;
            var count = this.swiper.slides.length;
            if (pos === ':end' && offset === -1) {
                return count - 1;
            } else if (_.isString(pos) || pos instanceof Marionette.View) {
                var view = this.get(pos);
                pos = view ? this.swiper.slides.indexOf(view.el) : 0;
            }
            pos = pos + (offset || 0);
            if (pos < 0 && offset >= 0) pos = count + pos; // from end
            if (pos <= 0) return 0;
            if (pos >= count) return count - 1;
            return pos;
        },
        
        _normalizeIndex: function(index) {
            if (index > 0 && index < this.swiper.slides.length) {
                index -= this.swiper.loopedSlides || 0;
            }
            return index;
        },
        
        _canSwipe: function() { 
            if (this.scrollLock) return false;
            var active = this.get(':active') || this.get(':first');
            if (_.isObject(this.delegate) && _.isFunction(this.delegate.canSwipe)) {
                if (this.delegate.canSwipe(this, active) === false) return false;
            }
            if (this.canSwipe() === false) return false;
            return active && active.canSwipe();
        },
        
        _updateTargetView: function(flag, root) {
            root = root || this.getRoot();
            if (flag === 0) return;
            this.targetView = this.get(flag > 0 ? ':next' : ':prev');
            var direction = this.isHorizontal() ? (flag > 0 ? 'right' : 'left') : (flag > 0 ? 'down' : 'up');
            root.$el.addClass('swipe swipe-' + direction);
            if (this.targetView) {
                var to = flag > 0 ? 'next' : 'prev';
                this.triggerMethod('slide:target', to, this.targetView);
                if (this !== root) root.triggerMethod('slide:target', to, this.targetView);
            }
        },
        
        _fetchedView: function(view, callback) {
            if (_.isObject(this.delegate) && _.isFunction(this.delegate.fetchedView)) {
                this.fetchedView(view, function() {
                    this.delegate.fetchedView(view, callback.bind(this));
                }.bind(this));
            } else {
                this.fetchedView(view, callback.bind(this));
            }
        },
        
        // Events
        
        onViewPull: function(direction, view) {
            if (this.swiper.params.freeMode) return;
            var root = this.getRoot();
            this.scrollLock = root.scrollLock = false; // remove lock
            this.navigate(direction);
        },
        
        onResistanceBefore: function(swiper, p) {
            this.swiper.holdPosition = p;
            var pullable = Marionette.getOption(this, 'pull') === true;
            if (!pullable || p < 50 || this.locked) return;
            var parent = this.getParent();
            if (parent) {
                this.locked = true;
                parent.show(':prev', function() {
                    this.locked = false;
                }.bind(this));
            }
        },
        
        onResistanceAfter: function(swiper, p) {
            this.swiper.holdPosition = p;
            var pullable = Marionette.getOption(this, 'pull') === true;
            if (!pullable || p < 50 || this.locked) return;
            var parent = this.getParent();
            if (parent) {
                this.locked = true;
                parent.show(':next', function() {
                    this.locked = false;
                }.bind(this));
            }
        },
        
        onTouchMove: function() {
            var root = this.getRoot();
            var treshold = Marionette.getOption(this, 'swipeTreshold') || 10;
            var swipe = this.swiper.touches.abs > treshold || !this.swiper.isTouched;
            if (swipe && this.swiper.isTouched) {
                var increased = this.swiper.touches.start > this.swiper.touches.current;
                this._updateTargetView(increased ? 1 : -1 , root);
            }
            if (swipe) root.$el.addClass('swipe');
            if (this.swiper.isTouched) root.$el.addClass('touched');
        },
        
        onSlideTouch: function() {
            var root = this.getRoot();
            var view = this.swiper.clickedSlide && this.swiper.clickedSlide.view;
            if (this.swiper.params.freeMode) {
                this.scrollLock = !this.isHorizontal() && view && view.isScrollable('vertical');
                this.scrollLock = this.scrollLock && !(view.scroller.topActive && view.scroller.bottomActive);
            }
        },
        
        onTouchStart: function() {
            this.swiper.isTouched = this._canSwipe();
            this.swiper.holdPosition = 0;
        },
        
        onTouchEnd: function() {
            var holdBefore = Marionette.getOption(this, 'holdBefore');
            var holdAfter = Marionette.getOption(this, 'holdAfter');
            
            var holdTreshold = Marionette.getOption(this, 'holdTreshold');
            if (_.isFunction(holdTreshold)) holdTreshold = holdTreshold.call(this);
            
            var holdPosition = Marionette.getOption(this, 'holdPosition') || holdTreshold;
            if (_.isFunction(holdPosition)) holdPosition = holdPosition.call(this);
            
            var before = this.swiper.positions.diff > 0;
            if (_.isNumber(holdTreshold) && ((before && holdBefore) || (!before && holdAfter))
                && this.swiper.holdPosition > holdTreshold) {
                var offset = this.swiper.positions.start;
                offset += (before ? holdPosition : -holdPosition);
                if (this.isHorizontal()) { // Hold Swiper in required position
                    this.swiper.setWrapperTranslate(offset,0,0);
                } else {
                    this.swiper.setWrapperTranslate(0,offset,0);
                }
                this.setFlags({ onlyExternal: true });
                this.triggerMethod('hold:' + (before ? 'before' : 'after'));
            } else {
                this.performTouchEnd();
            }
        },
        
        onScrollStart: function() {
            this.getRoot().$el.addClass('touched');
        },
        
        onScrollEnd: function() {
            this.getRoot().$el.removeClass('touched');
        },
        
        releaseHold: function() {
            this.swiper.holdPosition = 0;
            this.performTouchEnd();
        },
        
        performTouchEnd: function() {
            var horizontal = this.isHorizontal();
            var self = this, root = this.getRoot();
            this.scrollLock = root.scrollLock = false;
            setTimeout(function() {
                root.$el.removeClass('swipe touched sliding');
                root.$el.removeClass(horizontal ? 'swipe-left swipe-right' : 'swipe-up swipe-down');
                self.targetView = null;
            }, Marionette.getOption(this, 'touchTimeout') || 400);
        },
        
        onSlideStart: function(swiper, position, action, options) {
            this.onTouchMove();
            this.previousView = this.get(':active');
            this.swiper.cancel = !this._canSwipe();
            var increased = action === 'next';
            increased = increased || (action === 'to' && (options ? options.index > this.swiper.activeIndex : false));
            if (options && options.index === this.swiper.activeIndex) action = 'reset';
            this._updateTargetView(action === 'reset' ? 0 : (increased ? 1 : -1));
            if (_.result(this, 'optimize') && action !== 'reset') {
                var views = this.views();
                var index = _.has(options, 'index') ? options.index : views.indexOf(this.targetView);
                var offset = this.swiper.params.slidesPerView || 1;
                var range = [index, index + offset];
                _.each(views, function(vw, idx) {
                    vw.setVisibility(vw.isVisible() || (idx >= range[0] && idx < range[1]));
                });
            }
        },
        
        onSlideEnd: function() {
            if (this.swiper.params.freeMode) this.updateUI();
            if (this.swiper.params.freeMode) this.updateVisibility();
            this.targetView = null;
        },
        
        onSlideReset: function() {
            this.triggerMethod('touch:reset', this.swiper, this.targetView);
            this.performTouchEnd();
        },
        
        onSlideChangeStart: function() {
            this.$el.addClass('sliding');
        },
        
        onSlideChangeEnd: function() {
            this.$el.removeClass('sliding');
            this.performTouchEnd();
            this.updateFocus();
        }
        
    });
    
    return Marionette.SlideView = SlideView;
    
}));