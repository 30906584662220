
(function (factory) {
    if (typeof define === 'function' && define.amd) {
        define('marionette.swipeview',['underscore', 'marionette', 'swiper', 'swiper.progress'], factory);
    } else {
        factory(_, Marionette, Swiper);
    }
}(function(_, Marionette, Swiper) {
        
    var SwipeView = Marionette.ItemView.extend({
    
        className: 'swiper-container',
        
        constructor: function(options) {
            var defaults = {};
            this.options = _.extend(defaults, this.options, options);
            this.config = _.extend({ calculateHeight: true }, this.config, options.config);
            Marionette.View.prototype.constructor.apply(this, arguments);
        },
        
        show: function(pos, speed, runCallbacks) {
            if (_.isString(pos)) {
                var match = -1;
                var slide = _.find(this.swiper.slides, function(slide, idx) {
                     if ($(slide).data('name') === pos) match = idx;
                     return match !== -1;
                });
                if (match > -1) this.swiper.swipeTo(match, speed, runCallbacks);
            } else if (_.isNumber(pos)) {
                this.swiper.swipeTo(pos, speed, runCallbacks);
            }            
        },
        
        render: function() {
            if (Marionette.getOption(this, 'template')) Marionette.ItemView.prototype.render.apply(this, arguments);
            
            this.$wrapper = this.$el.children('.swiper-wrapper').eq(0);
            if (!this.$wrapper.is('*')) {
                this.$wrapper = this.$el.wrapInner('<div class="swiper-wrapper">').children(':first');
            }
            
            var items = this.$wrapper.children().detach();
            
            if (!this.swiper) {
                this.$el.addClass(this.className);
                var options = _.extend({}, this.config);
                options.marionette = { view: this }; // pseudo-plugin
                
                if (_.isObject(options.scrollbar) && !_.isString(options.scrollbar.container) 
                    && this.$el.children('.swiper-scrollbar').length === 0) {
                    var scrollbar = $('<div class="swiper-scrollbar">');
                    this.$el.append(scrollbar);
                    options.scrollbar.container = scrollbar[0];
                    options.onScrollbarDrag = this.triggerMethod.bind(this, 'scrollbar:drag');
                }
                
                var swiper = this.swiper = new Swiper(this.el, options);
                
                swiper.addCallback('TouchStart', this.triggerMethod.bind(this, 'touch:start'));
                swiper.addCallback('TouchMove',  this.triggerMethod.bind(this, 'touch:move'));
                swiper.addCallback('TouchEnd',   this.triggerMethod.bind(this, 'touch:end'));
                swiper.addCallback('SlideReset', this.triggerMethod.bind(this, 'slide:reset'));
                swiper.addCallback('SlideClick', this.triggerMethod.bind(this, 'slide:click'));
                swiper.addCallback('SlideTouch', this.triggerMethod.bind(this, 'slide:touch'));
                
                swiper.addCallback('BeforeSlide', this.triggerMethod.bind(this, 'slide:start'));
                
                swiper.addCallback('SlideChangeStart', this.triggerMethod.bind(this, 'slide:change:start'));
                swiper.addCallback('SlideChangeEnd',   this.triggerMethod.bind(this, 'slide:change:end'));
                            
                swiper.addCallback('ResistanceBefore', this.triggerMethod.bind(this, 'resistance:before'));
                swiper.addCallback('ResistanceAfter',  this.triggerMethod.bind(this, 'resistance:after'));
                
                swiper.addCallback('BeforeSlide', this.triggerMethod.bind(this, 'slide:start')); // can cancel
                swiper.addCallback('SetWrapperTransition', this.triggerMethod.bind(this, 'slide:end'));
                
                this.on('prev', this.swiper.swipePrev.bind(this.swiper));
                this.on('next', this.swiper.swipeNext.bind(this.swiper));
                this.on('show', this.show.bind(this));
            }
            
            items.each(function(idx, item) { this.append(item); }.bind(this));
            
            this.swiper.reInit();
            this.bindUIElements();
        },
        
        refresh: function() {
            this.render();
        },
        
        prepend: function(html) {
            this.swiper.prependSlide(this.wrapSlideElem(html));
        },
        
        append: function(html) {
            this.swiper.appendSlide(this.wrapSlideElem(html));
        },
        
        insertAfter: function(index, html) {
            this.swiper.insertSlideAfter(index, this.wrapSlideElem(html));
        },
        
        wrapSlideElem: function(html) {
            if (_.isString(html)) {
                var slide = this.swiper.createSlide(html);
            } else if (html instanceof HTMLElement || html instanceof jQuery) {
                var $html = $(html);
                if ($html.hasClass('swiper-slide')) {
                    var slide = $html[0];
                    if (!_.isObject(slide.swiperSlideDataStorage)) {
                        this.swiper._extendSwiperSlide(slide);
                    }
                } else if ($html.is('*')) {
                    var slide = this.swiper.createSlide($html[0].outerHTML);
                }
            }
            if (slide) this.triggerMethod('slide:wrap', $(slide));
            return slide;
        }
        
    });
    
    return Marionette.SwipeView = SwipeView;
    
}));