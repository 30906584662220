
(function (factory) {
    if (typeof define === 'function' && define.amd) {
        define('marionette.gridview',['jquery', 'underscore', 'marionette', 'marionette.slideview', 'jquery.transit'], factory);
    } else {
        factory(jQuery, _, Marionette);
    }
}(function($, _, Marionette) {
    
    _.mixin({
        chunk: function (array, unit) {
            if (!_.isArray(array)) return array;
            unit = Math.abs(unit || 10);
            var results = [],
            length = Math.ceil(array.length / unit);
            for (var i = 0; i < length; i++) {
                results.push(array.slice( i * unit, (i + 1) * unit));
            }
            return results;
        }
    });
    
    var ItemView = Marionette.SlideItemView || Marionette.ItemView.extend({
        
        constructor: function(options) {
            Marionette.ItemView.prototype.constructor.apply(this, arguments);
            if (Backbone.Courier) Backbone.Courier.add(this);
            this.triggerMethod('construct', options);
        },
        
        render: function() {
            var template = this.getTemplate();
            if (template) {
                return Marionette.ItemView.prototype.render.apply(this, arguments);
            } else if (this.model) {
                return this.performRender(function() {
                    this.$el.html(this.model.toString());  
                });
            } else if (_.isString(this.content)) {
                return this.performRender(function() {
                    this.$el.html(this.content);
                });
            } else {
                return this.performRender();
            }
        },
        
        performRender: function(callback) {
            this.isDestroyed = false;
            
            if (this.spawn) this.spawn('before:render');
            this.triggerMethod('before:render', this);
            this.triggerMethod('before:render', this);
            
            if (callback) callback.call(this);
            
            this.bindUIElements();
            
            this.triggerMethod('render', this);
            this.triggerMethod('render', this);
            if (this.spawn) this.spawn('render');
            
            return this;
        }
        
    });
    
    var GridViewMixin = {
        
        validOptions: [
            'animate', 'animateTransition', 'layoutMode', 'itemRatio',
            'itemWidth', 'itemHeight', 'layout', 'handleOrientation',
            'margins', 'rows', 'cols', 'itemsPerView', 'itemSelector'
        ],
        
        maxItemsPerView: Number.POSITIVE_INFINITY,
        adaptiveLayout: true,   // auto-adapt layout to item count
        itemsPerView: 0,        // set 0 for auto-fill
        itemSelector: '.item',
        itemOperation: 'append',
        
        itemWidth: 0,
        itemHeight: 0,
        
        currentRows: 0,
        currentCols: 0,
        
        currentItemWidth: 0,
        currentItemHeight: 0,
        
        margins: 0,
        
        layoutRatio: 0,
        layoutMode: 'float', // float, relative, absolute, stack, rows, cols
        layout: null, // array or function
        handleOrientation: true,
        
        inactiveClass: 'inactive',
        layoutInactive: true, // set to false to use inactiveClass directly through css
        layoutInactiveReverse: false,
        forceFontsize: false,
        
        transformOrigin: 'center center',
        
        setupGrid: function(options) {
            var layoutMode = (options || {}).layoutMode || this.$el.data('layout-mode');
            this.layoutMode = layoutMode || this.layoutMode || 'float';
            if ((options || {}).responsive) this.responsive(true);
            this.options = this.options || {};
        },
        
        responsive: function(bool) {
            var initialize = _.once(function() {
                this.isResponsive = true;
                var updateLayout = this.updateLayout.bind(this, true);
                var callback = function() {
                    if (this.isResponsive === true) updateLayout();
                }.bind(this);
                var eventId = 'resize.' + this.cid;
                $(window).bind(eventId, _.throttle(callback, 100));
            }.bind(this));
            if (bool) initialize();
            this.isResponsive = bool;
        },
        
        isFull: function() {
            var itemsPerView = this._itemsPerView() || this._maxItemsPerView() || 0;
            if (itemsPerView === 0) return false;
            return this.items().length >= itemsPerView;
        },
        
        items: function(onlyActive) {
            var itemSelector = this._itemSelector();
            var items = this.$el.find(itemSelector);
            if (onlyActive) return items.not('.' + this.inactiveClass);
            return items;
        },
        
        views: function(onlyActive) {
            return _.compact(this.items(onlyActive).map(function() {
                return $(this).data('view');
            }));
        },
        
        onOverflow: function(items, options) { // default logic, overflow to inserted view
            if (!this.overflowView || this.overflowView.isFull()) {
                var lastView = this.overflowView || this;
                var defaults = _.pick(this.options, this.validOptions);
                var opts = _.extend({}, defaults, this.options.overflow);
                this.overflowView = new this.constructor(opts);
                this.triggerMethod('item:overflow:view', this.overflowView);
                this.overflowView.$el.insertAfter(lastView.el);
            }
            this.overflowView.addItems(items, options);
        },
        
        updateLayout: function(options, callback) {
            var afterResize = _.isBoolean(options) && options;
            
            if (_.isObject(options)) {
                _.extend(this, _.pick(options, this.validOptions));
            }
            
            var container = this.container ? this.container.$el : this.$el;
            var fixedContainerWidth  = _.result(this, 'width') || 0;
            var fixedContainerHeight = _.result(this, 'height') || 0;
            var width  = fixedContainerWidth || container.width();
            var height = fixedContainerHeight || container.height();
            var fixedWidth = _.result(this, 'itemWidth') || 0;
            var fixedHeight = _.result(this, 'itemHeight') || 0;
            var itemRatio = _.result(this, 'itemRatio') || 0;
            var itemsPerView = this._itemsPerView();
            var fixedLayout = _.result(this, 'layout');
            var adaptiveLayout = !itemsPerView && this.adaptiveLayout;
            var relativeLayout = this.layoutMode === 'float' || this.layoutMode === 'relative';
            var items = this.items();
            var count = itemsPerView || items.length;
            
            if (fixedWidth > 0 && fixedHeight === 0) {
                height = container.parent().height();
                this.$el.css('height', height + 'px');
            } else if (fixedHeight > 0 && fixedWidth === 0) {
                width = container.parent().width();
                this.$el.css('width', width + 'px');
            }
            
            if (fixedContainerWidth > 0) {
                this.$el.css('width', fixedContainerWidth + 'px');
            }
            
            if (fixedContainerHeight > 0) {
                this.$el.css('height', fixedContainerHeight + 'px');
            }
            
            var num_rows = this.options.rows || _.result(this, 'rows') || 0;
            var num_cols = this.options.cols || _.result(this, 'cols') || 0;
            
            var containerWidth = this.containerWidth = fixedWidth ? Number.POSITIVE_INFINITY : width;
            var containerHeight = this.containerHeight = fixedHeight ? Number.POSITIVE_INFINITY : height;
            
            if (adaptiveLayout && this.layoutInactive) {
                var partialCount = items.not('.' + this.inactiveClass).length;
                if (partialCount === count) {
                    var layout = this.calculateLayout(containerWidth, containerHeight, count);
                    var fullLayout = layout;
                } else {
                    var layout = this.calculateLayout(containerWidth, containerHeight, partialCount);
                    var fullLayout = this.calculateLayout(containerWidth, containerHeight, count);
                }
            } else {
                var layout = this.calculateLayout(containerWidth, containerHeight, count);
                var fullLayout = layout;
            }
            
            if (this.layoutMode === 'cols' && (fixedWidth > 0 || num_rows > 0 || num_cols > 0)) {
                fullLayout.reverse();
            } else if (this.layoutMode === 'rows' && (fixedHeight > 0 || num_rows > 0 || num_cols > 0)) {
                fullLayout.reverse();
            } else if (this.layoutMode === 'cols' && adaptiveLayout && this.layoutInactive) {
                layout.reverse();
                fullLayout.reverse();
            } else if (this.layoutMode === 'float' && fixedHeight > 0) {
                layout.reverse();
            }
            
            var rows = layout[1];
            var cols = layout[0];
            
            var margins = this.calculateMargins(rows, cols);
            var marginX = margins[0];
            var marginY = margins[1];
            
            if (this.layoutMode === 'rows' || this.layoutMode === 'cols' 
                || this.layoutMode === 'absolute') {
                if (marginX) width  -= ((cols - 1) * marginX);
                if (marginY) height -= ((rows - 1) * marginY);
                var itemW = Math.ceil(width/cols);
                var itemH = Math.ceil(height/rows);
                if (itemRatio > 0) itemH = itemRatio * itemW;
            } else {
                if (relativeLayout && marginX) width  -= (cols * (marginX * 2));
                if (relativeLayout && marginY) height -= (rows * (marginY * 2));
                var itemW = width/cols;
                var itemH = height/rows;
                if (itemRatio > 0) itemH = itemRatio * itemW;
                if (itemW % 0.5 !== 0) itemW -= 1/8; // fine-tune
                if (itemH % 0.5 !== 0) itemH -= 1/8; // fine-tune
            }
            
            if (fixedWidth > 0) {
                itemW = fixedWidth;
                containerWidth = itemW * cols;
                if (marginX) containerWidth += ((cols - 1) * marginX);
                this.$el.css('width', containerWidth + 'px');
            }
            
            if (fixedHeight > 0 || itemRatio > 0) {
                if (fixedHeight > 0) itemH = fixedHeight;
                containerHeight = itemH * rows;
                if (marginY) containerHeight += ((rows - 1) * marginY);
                this.$el.css('height', containerHeight + 'px');
            }
            
            var fullRows = fullLayout[1];
            var fullCols = fullLayout[0];
            
            if (this.layoutInactive && this.layoutMode === 'rows') {
                var finalCols = Math.min(cols, fullCols);
                var finalRows = Math.ceil(count/finalCols);
            } else if (this.layoutInactive && this.layoutMode === 'cols') {
                var finalRows = Math.min(rows, fullRows);
                var finalCols = Math.ceil(count/finalRows);
            } else {
                var finalRows = rows;
                var finalCols = cols;
            }
            
            this.currentItemWidth = itemW;
            this.currentItemHeight = itemH;
            
            this.currentRows = finalRows;
            this.currentCols = finalCols;
            
            this.applyLayout(items, finalRows, finalCols, itemW, itemH, afterResize);
            
            if (afterResize) this.updateItemsLayout();
            
            this.triggerMethod('update:layout', items, finalRows, finalCols, itemW, itemH);
            
            if (_.isFunction(callback)) callback(items, finalRows, finalCols, itemW, itemH);
        },
        
        updateItemsLayout: function() {
            _.each(this.views(), function(view) {
                if (view.updateLayout) view.updateLayout();
            });
        },
        
        applyLayout: function(items, rows, cols, width, height, afterResize) {
            this.$el.attr('data-layout-mode', this.layoutMode); // set as attribute
            if (this.forceFontsize) items.css('font-size', height + 'px');
            if (rows > 0 && cols > 0) {
                if (this.layoutMode === 'rows' || this.layoutMode === 'cols' 
                || this.layoutMode === 'absolute') {
                    items.css({ float: 'none', position: 'absolute' });
                    if (this.layoutInactive) {
                        var active = items.not('.' + this.inactiveClass).toArray();
                        var inactive = items.filter('.' + this.inactiveClass).toArray();
                        if (this.layoutInactiveReverse) {
                            var lookup = inactive.concat(active);
                        } else {
                            var lookup = active.concat(inactive);
                        }                        
                    } else {
                        lookup = items.toArray();
                    }
                    
                    lookup = this.sortItems ? (this.sortItems(lookup) || lookup) : lookup;
                    var margins = this.calculateMargins(rows, cols);
                    var marginX = margins[0];
                    var marginY = margins[1];
                    var columns = this.layoutMode === 'cols', item = null, self = this;
                    _.times(rows, function(row) {
                        _.times(cols, function(col) {
                            var idx = columns ? ((col * rows) + row) :  ((row * cols) + col);
                            if (idx < lookup.length && (item = lookup[idx])) {
                                var x = col * width, y = row * height;
                                if (marginX) x += (col * marginX);
                                if (marginY) y += (row * marginY);
                                self.applyTransform($(item), x, y, width, height, afterResize);
                            }
                        });
                    });
                } else if (this.layoutMode === 'relative') {
                    items.css({ float: '', position: '' });
                } else if (this.layoutMode === 'stack') {
                    items.each(function(idx) {
                        $(this).css({ float: '', position: 'absolute', 
                            top: 0, left: 0, zIndex: idx, width: '100%', height: '100%' });
                    });  
                } else { // float layout, box-sizing safe
                    items.css({ float: 'left', position: 'relative', width: width + 'px', height: height + 'px' });
                }
            }
            items.removeClass('fresh');
        },
        
        applyTransform: function(item, x, y, width, height, afterResize) {
            var self = this;
            var transform = Modernizr && Modernizr.csstransforms && this.layoutMode !== 'absolute';
            var transitions = Modernizr && Modernizr.csstransitions;
            var animate = this.options.animate || this.animate;
            var css = { float: 'none', position: 'absolute', minWidth: 'auto', minHeight: 'auto' };
            
            if (transform) item.css(Modernizr.prefixed('transformOrigin'), this.transformOrigin);
            
            if (transform && transitions && animate && _.isFunction($.fn.transition)) {
                var transitionEnd = 'transitionend webkitTransitionEnd oTransitionEnd otransitionend';
                var defaults = { queue: false, easing: 'snap', duration: 400 };
                if (_.isObject(animate)) _.extend(defaults, animate);
                var animateTransition = this.options.animateTransition || this.animateTransition;
                var method = (animateTransition && !afterResize) ? 'transition' : 'css';
                var defaultTransform = this.defaultTransform(item, x, y, width, height);
                if (_.isObject(animateTransition)) _.extend({}, defaultTransform, animateTransition);
                var onAfterTransform = this.options.onAfterTransform || this.onAfterTransform;
                
                var completeFn = function(kind, css, callback) {
                    return function() {
                        if (_.isFunction(callback)) callback.call(self, item);
                        if (css) item.css(css);
                        if (_.isFunction(onAfterTransform)) onAfterTransform.call(self, kind, item);
                        self.triggerMethod('item:transformed', kind, item);
                    }
                };
                
                var initialize = function() {
                    defaults.complete = completeFn('init');
                    item.css(css); // apply base css
                    var baseTransform = self.baseTransform(item, x, y, width, height);
                    item.css(_.extend({}, defaults, baseTransform));
                    item.transition(_.extend({}, defaults, defaultTransform));
                };
                
                var deactivate = function(css, callback) {
                    item.addClass('disabled');
                    var inactiveTransform = self.inactiveTransform(item, x, y, width, height);
                    if (_.isObject(animateTransition)) _.extend({}, inactiveTransform, animateTransition);
                    if (method === 'transition') {
                        defaults.complete = completeFn('deactivate', { display: 'none' }, callback);
                    } else {
                        if (_.isFunction(callback)) callback.call(self, item);
                        item.css(_.extend({ display: 'none' }, css));
                        self.triggerMethod('item:transformed', 'deactivate', item);
                    }
                    item[method](_.extend({}, defaults, inactiveTransform));
                };
                
                var displayOn = function() {
                    defaults.queue = true;
                    item.addClass('on').css({ zIndex: 9999 });
                    var displayTransform = self.displayTransform(item, x, y, width, height);
                    item[method](_.extend({}, defaults, displayTransform));
                    defaults.complete = completeFn('display:on', { zIndex: 9999 }); // assign here
                    if (method === 'transition') {
                        item[method](_.extend({}, defaults, { x: 0, y: 0 }));
                    } else {
                        item.css({ x: 0, y: 0, zIndex: 9999 });
                        self.triggerMethod('item:transformed', 'display:on', item);
                    }
                };
                
                var displayOff = function() {
                    defaults.queue = true;
                    var callback = function() { item.removeClass('on'); }
                    if (item.is('.inactive')) {
                        deactivate({ zIndex: '' }, callback);
                    } else {
                        if (method === 'transition') {
                            item[method](_.extend({}, defaults, { x: x, y: y }));
                        } else if (item.is('.on')) {
                            callback();
                            self.triggerMethod('item:transformed', 'display:off', item);
                        }
                        defaults.complete = completeFn('display:off', { zIndex: '' }, callback); // assign here
                        item[method](_.extend({}, defaults, defaultTransform));
                    }
                };
                
                if (item.is('.fresh')) { // initialize
                    initialize();
                } else if (item.is('.display')) { // display on
                    displayOn();
                } else if (!item.is('.display') && item.is('.on')) { // display off
                    displayOff();
                } else if (item.is('.inactive')) { // deactivate/hide
                    deactivate();
                } else if (!item.is('.inactive') && item.is('.disabled')) { // activate/show
                    defaults.complete = completeFn('activate', {}, function() {
                        item.removeClass('disabled');
                    });
                    item.css({ display: '' });
                    item[method](_.extend({}, defaults, defaultTransform));
                } else { // default update (on resize/redraw)
                    defaults.complete = completeFn('update');
                    item.css({ display: '' });
                    item[method](_.extend({}, defaults, defaultTransform));
                }
                
                this.triggerMethod('item:transform', item, x, y, width, height);
                return; // all set
            } else if (transform) {
                css[Modernizr.prefixed('transform')] = 'translate(' + x + 'px,' + y + 'px)';
            } else {
                css['left'] = x;
                css['top'] = y;
            }
            css['width'] = width + 'px'; // box-sizing safe
            css['height'] = height + 'px';
            item.css(css);
            this.triggerMethod('item:transform', item, x, y, width, height);
        },
        
        baseTransform: function(item, x, y, width, height) {
            return { x: x, y: y, width: width, height: height, opacity: 0.0 };
        },
        
        defaultTransform: function(item, x, y, width, height) {
            return { x: x, y: y, width: width, height: height, opacity: 1.0 };
        },
        
        displayTransform: function(item, x, y, width, height) {
            return { width: this.containerWidth, height: this.containerHeight };
        },
        
        inactiveTransform: function(item, x, y, width, height) {
            return { opacity: 0.0 };
        },
        
        calculateMargins: function(rows, cols) {
            var margins = this.options.margins || this.margins;
            if (_.isFunction(margins)) {
                return margins.call(this, rows, cols) || [0, 0];
            } else if (_.isArray(margins)) {
                return margins;
            } else if (_.isNumber(margins)) {
                return [margins, margins];
            } else {
                return [0, 0];
            }
        },
        
        calculateLayout: function(width, height, num_items) {
            var layout = _.result(this, 'layout');
            if (_.isArray(layout) && layout.length === 2) return layout;
            var itemRatio = _.result(this, 'itemRatio') || 0;
            var num_rows = this.options.rows || _.result(this, 'rows') || 0;
            var num_cols = this.options.cols || _.result(this, 'cols') || 0;
            var preferred_ratio = this.layoutRatio || 0;
            var fixedOrientation = this.layoutMode === 'float' && itemRatio > 0;
            
            if (num_cols > 0 && num_rows === 0) {
                num_rows = Math.ceil(num_items / num_cols);
            } else if (num_rows > 0 && num_cols === 0) {
                num_cols = Math.ceil(num_items / num_rows);
            }
            
            if (num_rows === 0 && preferred_ratio == 0) {
                num_rows = Math.floor(Math.sqrt(num_items));
            } else if (num_rows === 0) {
                var desired_aspect = (width / height) / preferred_ratio;
                num_rows = Math.floor(Math.sqrt(num_items / desired_aspect));
            }
            
            num_cols = num_cols || Math.ceil(num_items / num_rows);
            if (!this.handleOrientation || fixedOrientation) return [num_cols, num_rows];
            
            if (this.freeOrientation === true) {
                var rows = this.options.rows || _.result(this, 'rows') || 0;
                var cols = this.options.cols || _.result(this, 'cols') || 0;
                if ((cols === 0 && height === Number.POSITIVE_INFINITY) ||
                    (rows === 0 && width === Number.POSITIVE_INFINITY)) {
                    var maxWidth = width === Number.POSITIVE_INFINITY ? 0 : width;
                    var maxHeight = height === Number.POSITIVE_INFINITY ? 0 : height;
                    var fixedWidth = _.result(this, 'itemWidth') || 0;
                    var fixedHeight = _.result(this, 'itemHeight') || 0;
                    width = Math.max(fixedWidth * num_cols, maxWidth);
                    height = Math.max(fixedHeight * num_rows, maxHeight);
                    if (this.layoutMode === 'float') {
                        return width > height ? [num_rows, num_cols] : [num_cols, num_rows];
                    }
                }
            }
            
            return width > height ? [num_cols, num_rows] : [num_rows, num_cols];
        },
        
        onResize: function() {
            this.updateLayout(true);
        },
        
        onDestroy: function() {
            $(window).unbind('resize.' + this.cid);
        },
        
        _itemsPerView: function() {
            if (!_.has(this, '__itemsPerView')) { // cache, because it cannot vary
                var layout = _.result(this, 'layout');
                if (_.isArray(layout) && layout.length === 2) {
                    var num_rows = layout[0];
                    var num_cols = layout[1];
                } else {
                    var num_rows = this.options.rows || _.result(this, 'rows') || 0;
                    var num_cols = this.options.cols || _.result(this, 'cols') || 0;
                }
                if (num_rows && num_cols) {
                    this.__itemsPerView = num_rows * num_cols;
                } else {
                    this.__itemsPerView = _.result(this, 'itemsPerView');
                }
            }
            return this.__itemsPerView;
        },
        
        _maxItemsPerView: function() {
            var max = _.result(this.options, 'maxItemsPerView') || _.result(this, 'maxItemsPerView');
            return max || Number.POSITIVE_INFINITY;
        },
        
        _itemSelector: function() {
            return _.result(this, 'itemSelector');
        }
        
    };
    
    var BaseGridViewItem = ItemView.extend(_.extend({}, GridViewMixin, {
        
        collectionEvents: {
            'add': 'onCollectionAdd',
            'remove': 'onCollectionRemove',
            'sort': 'onCollectionSort',
            'reset': 'onCollectionReset'
        },
        
        constructor: function(options) {
            ItemView.prototype.constructor.apply(this, arguments);
            this.setupGrid(options);
        },
        
        setContent: function(data) {
            this.removeItems();
            if (data instanceof Backbone.Model) {
                if (data.has('items')) {
                    return this.addItems(data.get('items'));
                } else {
                    return this.addItems(data);
                }
            } else {
                this.addItems(data);
            }
        },
        
        displayItem: function(filter) {
            var items = this.items().removeClass('display');
            if (filter && items.filter('.on').length === 0) {
                items.filter(filter).eq(0).addClass('display');
            }
            this.updateLayout();
        },
        
        filterItems: function(filterFn) {
            var items = this.items();
            var inactiveClass = this.inactiveClass;
            if (_.isFunction(filterFn)) {
                items.each(function() {
                    var item = $(this);
                    var method = filterFn(item) ? 'removeClass' : 'addClass';
                    item[method](inactiveClass);
                });
            } else {
                items.removeClass(inactiveClass);
            }
            this.updateLayout();
        },
        
        sortItems: function(items) {
            if (this.collection && this.collection.length) {
                if (_.isArray(this.presortedItems)) return this.presortedItems;
                var lookup = _.pluck(this.collection.models, 'cid');
                this.presortedItems = _.sortBy(items, function(item) {
                    var index = lookup.indexOf($(item).data('cid'));
                    if (index === -1) return Number.MAX_VALUE;
                    return index;
                });
                return this.presortedItems;
            } else {
                return items;
            }
        },
        
        addItems: function(items, options, overflowFn) {
            if(_.isFunction(options)) overflowFn = options, options = {};
            options = _.extend({}, this.options.itemOptions, options);
            
            if (!_.isFunction(overflowFn)) overflowFn = this.onOverflow.bind(this);
            var done = _.isFunction(options.done) ? options.done : null;
            
            var itemSelector = this._itemSelector();
            
            if (_.isString(items) || items instanceof $ || items instanceof HTMLElement) {
                var newItems = $('<div>').html(items).find(itemSelector);
            } else if (_.isArray(items)) { // array of objects/models
                var newItems = $(_.map(items, this.renderItem.bind(this)));
                newItems.filter(itemSelector); // valid only
            } else if (_.isObject(items)) { // object/model
                var html = this.renderItem(items);
                return this.addItems(html, options, overflowFn);
            } else {
                return; // invalid input
            }
            
            if (_.isNumber(options.delay) && newItems.length > 1) { // stepwise/delay
                var opts = _.omit(options, 'delay', 'done', 'step');
                var idx = 0, step = options.step || 1;
                var interval = setInterval(function() {
                    this.addItems(newItems.slice(idx, idx + step), opts, overflowFn);
                    if ((idx += step) >= newItems.length) {
                        if (done) done(newItems);
                        clearInterval(interval);
                    }
                }.bind(this), options.delay);
                return;
            }
            
            var itemsPerView = this._itemsPerView();
            var items = this.items();
            
            delete this.presortedItems; // reset
            
            var operation = options.operation || this.itemOperation || 'append';
            var max = this._maxItemsPerView();
            var gap  = (itemsPerView || max) - items.length;
            var fill = newItems.slice(0, gap);
            var rest = newItems.slice(gap);
            
            fill.each(function(idx, item) {
                var $item = $(item);
                $item.addClass('fresh');
                
                var offset = items.length + idx;
                var info = _.extend({ offset: offset, mode: operation }, options);
                
                if (options.data) {
                    info.data = options.data;
                } else if (this.collection && this.collection.length) {
                    info.data = this.collection.get($(item).data('cid'));
                }
                
                $item = this.wrapView($item, info);
                
                var view = $item.data('view');
                
                if (view instanceof Marionette.View) {
                    view.triggerMethod('before:view:add', this, info);
                    view.render();
                }
                
                this.$el[operation]($item); // add to DOM
                
                if (view instanceof Marionette.View) {
                    view.triggerMethod('view:add', this, info);
                }
                
                this.triggerMethod('item:add', $item, info);
                if (this.container) this.container.triggerMethod('item:add', $item, info);
                $item.trigger('item:add', this, info); // jquery event
            }.bind(this));
            
            if (fill.length > 0 && options.update !== false) this.updateLayout();
            
            if (rest.length > 0) {
                this.triggerMethod('item:overflow', rest, options);
                if (this.container) this.container.triggerMethod('item:overflow', rest, options);
                overflowFn(rest, options);
            } else if (rest.length === 0) {
                this.triggerMethod('item:complete', options);
                if (this.container) this.container.triggerMethod('item:complete', options);
            }
            
            if (done && fill.length > 0 && rest.length > 0) done(fill);
        },
        
        removeItems: function(filterFn) {
            var removeCall = function(item, offset) {
                this.triggerMethod('item:remove', item, offset);
                if (this.container) this.container.triggerMethod('item:remove', item, offset);
            }.bind(this);
            
            delete this.presortedItems; // reset
            
            var allItems = this.items();
            if (_.isFunction(filterFn)) {
                allItems.filter(function(idx) {
                    var $item = $(this);
                    if (filterFn($item)) {
                        removeCall($item, idx)
                        return true;
                    }
                }).remove();
            } else if (arguments.length === 0) {
                allItems.remove();
            }
            
            this.updateLayout();
        },
        
        renderItem: function(item) {
            var childTemplate = Marionette.getOption(this, 'childTemplate');
            if (!childTemplate) return;
            var data = _.isFunction(item.toJSON) ? item.toJSON() : item;
            data = this.mixinTemplateHelpers(_.extend({}, data));
            var $html = $(Marionette.Renderer.render(childTemplate, data));
            if (item.cid) $html.attr('data-cid', item.cid);
            return $html[0]; // return plain HTMLElement
        },
        
        loadImages: function(callback) {
            callback = callback || this.triggerMethod.bind(this, 'images:ready');
            var images = this.$el.find('img');
            var loaded = 0;
            images.each(function() {
                if ($(image).data('imgloaded')) return;
                var image = new Image();
                $(image).one('load', function() {
                    loaded++;
                    $(this).data('imgloaded', true);
                    if (loaded === images.length) callback();
                });
                image.src = $(this).attr('src');
            });
        },
        
        onRender: function() {
            this.$el.addClass(this.className);
            var itemSelector = this._itemSelector();
            var items = this.$el.find(itemSelector).detach();
            
            if (items.length > 0) this.addItems(items);
            
            if (this.collection && this.collection.models 
                && this.collection.length > 0) {
                this.addItems(this.collection.models);
            }
            
            this.once('images:ready', this.updateLayout.bind(this));
            this.loadImages();
            this.updateLayout();
        },
        
        onCollectionAdd: function(model) {
            this.addItems(model, { data: model });
        },
        
        onCollectionRemove: function(model) {
            this.removeItems(function(item) {
                return item.data('cid') === model.cid;
            });
        },
        
        onCollectionSort: function() {
            delete this.presortedItems;
            this.updateLayout();
        },
        
        onCollectionReset: function(collection, options) {
            this.removeItems();
            this.addItems(collection.models, _.pick(options || {}, 'delay'));
        },
         
        wrapView: function(item, options) {
            var childView = Marionette.getOption(this, 'childView');
            if (!childView) return item;
            if (options.data instanceof Backbone.Model) {
                view = new childView({ el: item[0], model: options.data, container: this });
            } else {
                view = new childView({ el: item[0], container: this });
            }
            item.data('view', view);
            return item;
        }
        
    }));
    
    var GridViewItem = BaseGridViewItem.extend({
        
        onOverflow: function(items, options) {
            if (this.container) {
                var view = this.container.addItems(items, options);
                this.triggerMethod('item:overflow:view', view);
                this.container.triggerMethod('item:overflow:view', view);
            }
        },
        
        itemsPerView: function() {
            var count = this.container ? _.result(this.container, 'itemsPerView') : null
            return count || this.options.itemsPerView;
        },
        
        maxItemsPerView: function() {
            var count = this.container ? _.result(this.container, 'maxItemsPerView') : null
            return count || this.options.maxItemsPerView;
        },
        
        itemSelector: function() {
            var selector = this.container ? this.container.itemSelector : null;
            return selector || this.options.itemSelector || '.item';
        }
        
    });
    
    if (Marionette.SlideView) { // Begin Optional
        
    var GridView = Marionette.SlideView.extend({
        
        // Displays several slides with sets of items in a grid
        
        className: 'grid-view',
        
        childView: GridViewItem,
        
        itemSelector: '.item',
        
        itemsPerView: 6,
        
        maxItemsPerView: 16,
                
        grid: {},
        
        items: function() {
            return this.$el.find(this.itemSelector);
        },
        
        selectItem: function(item) {
            var items = this.items().removeClass('active');
            var filter = _.isString(item) ? '[data-name="' + item + '"]' : item;
            if (filter) items.filter(filter).addClass('active');
        },
        
        getSelectedItem: function() {
            return this.items().filter('.active').eq(0);
        },
        
        getPrevItem: function(loop) {
            var item = this.getSelectedItem()[0];
            var items = this.items();
            var index = items.index(item) - 1;
            var prev = items.eq(index);
            return prev.length > 0 ? prev : (loop ? items.last() : prev);
        },
        
        getNextItem: function(loop) {
            var item = this.getSelectedItem()[0];
            var items = this.items();
            var index = items.index(item) + 1;
            var next = items.eq(index);
            return next.length > 0 ? next : (loop ? items.first() : next);
        },
        
        addItems: function(items, options, callback) {
            options = _.extend({}, options);
            options._offset = options._offset || 0;
            options.done = options.done || callback;
            var opts = { done: options.done };
            var op = options.operation || 'append';
            if (options.view instanceof Marionette.ItemView) {
                var view = options.view;
                if (view.isFull()) view = this.insert(options._offset, '', options);
            } else {
                var view = this.get(op === 'prepend' ? ':first' : ':last');
                if (!view || view.isFull()) view = this[op]('', options);
            }
            if (op === 'prepend') options.view = view;
            view.addItems(items, opts, function(overflow) {
                options._offset += 1;
                this.addItems(overflow, options);
            }.bind(this));
            return view;
        },
        
        filterItems: function(filterFn) {
            _.each(this.views(), function(view) {
                if (_.isFunction(view.filterItems)) {
                    view.filterItems(filterFn);
                }
            });
        },
        
        onRender: function() {
            this.updateLayout();
        },
        
        updateWrapper: function(wrapper, html) {
            if (html.is('.swiper-slide')) {
                this.clear();
                this.addItems(html.children(this.itemSelector));
            } else {
                Marionette.SlideView.prototype.apply(this, arguments);
            }
        },
        
        onResize: function() {
            this.updateLayout(true);
        },
        
        updateLayout: function(afterResize) {
            var views = this.views();
            var callback = _.after(views.length, function() {
                this.triggerMethod('update:layout');
            }.bind(this));
            _.invoke(views, 'updateLayout', afterResize, callback);
        },
        
        childViewOptions: function(slide, options) {
            return _.extend({}, this.grid, options);
        },
        
        // pageable integration
        
        slidesPerPage: function() {
            var count = this._slidesPerPage(true);
            return Math.ceil(count / _.result(this, 'itemsPerView'));
        }
        
    });
    
    } // End Optional
    
    var GridCollectionView = Marionette.CollectionView.extend(_.extend({}, GridViewMixin, {
        
        layoutMode: 'float',
        
        constructor: function(options) {
            Marionette.CollectionView.prototype.constructor.apply(this, arguments);
            options = options || {};
            this.setupGrid(options);
            this.children.parentEl = this.$el;
            this.on('render', this.updateLayout);
            this.listenTo(this.collection, 'add remove reset sort', this.updateLayout);
            if (_.isFunction(this.collection.addListener)) { // requires emit mixin
                this.collection.addListener('before:remove', this);
                this.collection.addListener('before:reset', this);
                this.collection.addListener('before:sort', this);
                this.listenTo(this.collection, 'after:add', this.onAfterAdd);
            }
        },
        
        animationOptions: function() {
            var options = _.result(this, 'collectionAnimation');
            if (options) return _.extend({}, _.isObject(options) ? options : {});
        },
        
        onAfterAdd: function(model, options) {
            options = options || {};
            if (options.animate === false) return;
            var animate = this.animationOptions();
            if (animate && _.isFunction(this.children.animateModels)) {
                var dfd = $.Deferred();
                var opts = _.extend(animate, options.animate);
                this.children.animateModels(this.collection.models, opts, dfd.resolve);
                return dfd.promise();
            }
        },
        
        onBeforeRemove: function(models, options) {
            options = options || {};
            if (options.animate === false) return;
            var animate = this.animationOptions();
            if (animate && _.isFunction(this.children.animateModels)) {
                models = [].concat(models);
                var dfd = $.Deferred();
                var opts = _.extend(animate, options.animate);
                var remaining = _.difference(this.collection.models, models);
                this.children.animateModels(remaining, opts, dfd.resolve);
                return dfd.promise();
            }
        },
        
        onBeforeReset: function(models, options) {
            options = options || {};
            if (options.animate === false) return;
            var animate = this.animationOptions();
            if (!_.isArray(models) || !(_.first(models) instanceof Backbone.Model)) return;
            if (animate && _.isFunction(this.children.animateModels)) {
                var dfd = $.Deferred();
                var opts = _.extend(animate, options.animate);
                this.children.animateModels(models, opts, dfd.resolve);
                return dfd.promise();
            }
        },
        
        onBeforeSort: function(options) {
            options = options || {};
            if (options.animate === false) return;
            var animate = this.animationOptions();
            if (this.collection.comparator && animate && _.isFunction(this.children.animateModels)) {
                var coll = this.collection;
                if (_.isString(coll.comparator) || coll.comparator.length === 1) {
                    var models = coll.sortBy(coll.comparator, coll);
                } else {
                    var models = coll.toArray().sort(_.bind(coll.comparator, coll));
                }
                var dfd = $.Deferred();
                var opts = _.extend(animate, options.animate);
                this.children.animateModels(models, opts, dfd.resolve);
                return dfd.promise();
            }
        }
        
    }));
    
    Marionette.GridViewMixin = GridViewMixin;
    Marionette.GridCollectionView = GridCollectionView;
    Marionette.BaseGridViewItem = BaseGridViewItem;
    Marionette.GridContainer = BaseGridViewItem;
    Marionette.GridViewItem = GridViewItem;
    Marionette.GridView = GridView;
    
    return Marionette.GridView;
    
}));