
(function (factory) {
    if (typeof define === 'function' && define.amd) {
        define('marionette.cycleview',['jquery', 'underscore', 'marionette', 'jquery.cycle', 'jquery.maximage'], factory);
    } else {
        factory(jQuery, _, Marionette);
    }
}(function($, _, Marionette) {
    
    var CycleView = Marionette.ItemView.extend({
        
        className: 'media-cycle',
        
        wrapperClassName: 'slides',
        
        constructor: function(options) {
            options = _.extend({ lock: true }, options);
            this.options = _.pick(options, 'pause', 'lock', 'navigate');
            this.config = _.extend({}, this.constructor.config, options.config);
            this.cycleOptions = _.extend({}, this.constructor.cycleOptions, options.cycle);
            Marionette.ItemView.prototype.constructor.apply(this, arguments);
        },
        
        onBlur: function() {
            if (this.options.lock) this.cycle('pause');
        },
        
        onFocus: function() {
            if (this.options.lock) this.cycle('resume');
        },
        
        canNavigate: function(direction) {
            if (direction === 'left' || direction === 'right') {
                return this;
            } else {
                return false; // explicit false
            }
        },
        
        navigate: function(direction, options, callback) {
            if (direction === 'left') this.cycle('prev');
            if (direction === 'right') this.cycle('next');
            if (_.isFunction(callback)) this.once('cycle:change', callback);
        },
        
        cycle: function(command) {
            var cycle = this.$wrapper.data('cycle.opts');
            var isLite = _.has(this.$wrapper[0], 'cyclePause');
            if (cycle && command) { // only works for jquery.cycle.all.js
                this.$wrapper.cycle(command);
            } else if (isLite) { // lite
                if (command === 'pause') {
                    this.$wrapper[0].cyclePause = 1;
                } else if (command === 'resume') {
                    this.$wrapper[0].cyclePause = 0;
                }
            }
            return cycle || (isLite ? this.$wrapper[0] : null);
        },
        
        updateState: function(curr, next, opts, fwd) {
            this.state = _.pick(opts, 'currSlide', 'slideCount', 'nextSlide');
            this.triggerMethod('cycle:state', this.state);
        },
        
        render: function() {
            if (Marionette.getOption(this, 'template')) Marionette.ItemView.prototype.render.apply(this, arguments);
            
            this.$wrapper = this.$el.children('.' + this.wrapperClassName).eq(0);
            if (!this.$wrapper.is('*')) {
                this.$wrapper = this.$el.wrapInner('<div>').children(':first');
                this.$wrapper.addClass(this.wrapperClassName);
            }
            
            if (!this.cycle() && this.$('img').length > 0) {
                this.$el.addClass(this.className);
                var cycleDefaults = {};
                cycleDefaults.fx = 'fadeout';
                cycleDefaults.speed = 1000; // with cssTransitions enabled, match css3 transition speed
                cycleDefaults.sync = true;                
                cycleDefaults.prev = this.$el.find('[data-cycle="prev"]');
                cycleDefaults.next = this.$el.find('[data-cycle="next"]');
                cycleDefaults.before = this.triggerMethod.bind(this, 'cycle:before');
                cycleDefaults.after = this.triggerMethod.bind(this, 'cycle:after');
                cycleDefaults.onPagerEvent = this.triggerMethod.bind(this, 'cycle:pager');
                cycleDefaults.onPrevNextEvent = this.triggerMethod.bind(this, 'cycle:change');
                cycleDefaults.updateActivePagerLink = this.triggerMethod.bind(this, 'cycle:update:pager');
                var cycleOptions = _.extend(cycleDefaults, this.cycleOptions);
                var configDefaults = {};
                configDefaults.cssTransitions = false; // true needs jquery.cycle.all.js easing support
                var config = _.extend(configDefaults, this.config, { cycleOptions: cycleOptions });
                config.fillElement = this.el;
                config.onFirstImageLoaded = this.triggerMethod.bind(this, 'images:loaded:first');
                config.onImagesLoaded = this.triggerMethod.bind(this, 'images:loaded');
                if (_.isFunction(this.backgroundSize)) config.backgroundSize = this.backgroundSize.bind(this);
                this.$wrapper.maximage(config);
                this.on('cycle:after', this.updateState.bind(this));
                if (this.options.pause) this.on('cycle:init', this.cycle.bind(this, 'pause'));
                setTimeout(this.triggerMethod.bind(this, 'cycle:init'), 0);
            }
        },
        
        refresh: function() {
            this.render();
        }
        
    }, {
        config: { },
        cycleOptions: { }
    });    
    
    return Marionette.CycleView = CycleView;
    
}));