
(function (factory) {
    if (typeof define === 'function' && define.amd) {
        define('backbone.async.events',['jquery', 'underscore', 'backbone'], factory);
    } else {
        factory(jQuery, _, Backbone);
    }
}(function($, _, Backbone) {

    var AsyncEvents = function(options) {
        this.options = options;
        this.registry = {};
        if (_.isFunction(this.initialize)){
            this.initialize(options);
        }
    };
    
    _.extend(AsyncEvents.prototype, Backbone.Events, {
        
        listeners: function(event) {
            return this.registry[event] = this.registry[event] || [];
        },
        
        add: function(event, listener) {
            var listeners = this.listeners(event);
            if (!_.contains(listeners, listener)) {
                listeners.push(listener);
            }
        },
        
        remove: function(listener) {
            var items = _.toArray(arguments);
            _.each(this.registry, function(listeners, event) {
                this.registry[event] = _.without(listeners, items);
            }.bind(this));
        },
        
        applyEmit: function(event, args, callback) {
            var args = [event].concat(_.toArray(args));
            if (_.isFunction(callback)) args.push(callback);
            return this.emit.apply(this, args);
        },
        
        emit: function(event, callback) {
            var args = _.rest(arguments);
            var callback = _.isFunction(_.last(args)) ? args.pop() : null;
            var deferreds = [];
            _.each(this.listeners(event), function(listener) {
                if (_.isFunction(listener)) {
                    var deferred = listener.apply(null, [event].concat(args));
                } else {
                    var deferred = listener.triggerMethod.apply(listener, [event].concat(args));
                }
                if (_.isObject(deferred) && _.isFunction(deferred.then)) {
                    deferreds.push(deferred);
                } else if (_.isFunction(deferred) && deferred.length == 1) {
                    var dfd = $.Deferred();
                    deferred.call(listener, dfd.resolve);
                    deferreds.push(dfd.promise());
                }
            });
            return $.when.apply($, deferreds).then(callback);
        },
        
        clear: function(event) {
            if (event) {
                delete this.registry[event];
            } else {
                this.registry = {};
            }
        }
        
    });
    
    return Backbone.AsyncEvents = AsyncEvents;

}));