
(function (factory) {
    if (typeof define === 'function' && define.amd) {
        define('marionette.ext',['jquery', 'underscore', 'marionette'], factory);
    } else {
        factory(jQuery, _, Marionette);
    }
}(function($, _, Marionette) {
    
    _.extend(Marionette.Region.prototype, {
        
        // Because open will destroy $el, all $el.data() will be cleared
        // causing major issues with any views storing data there.
        // Notably, all Backbone.Courier based views.
        
        detachView: function(view) {
            if (this.currentView && view === this.currentView) {
                this.currentView.$el.detach();
                return this.currentView;
            }
        },
        
        // Similar to 'show' but enables switching between multiple views
        // without destroying them.
        // Handles calling the `render` method for you. Reads content
        // directly from the `el` attribute. Also calls an optional
        // `onSwap` on the region, `onSwappedIn` on the new view, and
        // `onSwappedOut` on the old view, just after rendering the view.
        // Returns the old view (if defined).
        
        swap: function(view) {
            if (view === this.currentView) return view;
            this._ensureElement();
            
            var oldView = this.detachView(this.currentView);
            
            view.render();
            _.isFunction(this.open) ? this.open(view) : this.show(view);
            this.currentView = view;
            this.currentView.delegateEvents();
            
            Marionette.triggerMethod.call(this, 'swap', view);
            if (oldView) Marionette.triggerMethod.call(oldView, 'swapped:out', view);
            Marionette.triggerMethod.call(view, 'swapped:in', oldView);
            
            return oldView;
        }
        
    });
    
    return Marionette;
    
}));