
(function (factory) {
    if (typeof define === 'function' && define.amd) {
        define('jquery.fittext',['jquery', 'underscore'], factory);
    } else {
        factory(jQuery);
    }
}(function($) {

  $.fn.fitText = function(kompressor, options) {

      // Setup options
      var compressor = kompressor || 1,
          settings = $.extend({
              watch         : false,
              live          : true,
              'minFontSize' : Number.NEGATIVE_INFINITY,
              'maxFontSize' : Number.POSITIVE_INFINITY
          }, options);
    
      var $this = $(this);
      var selector = this.selector;
    
      var resizer = function () {
          var elem = $(this);
          var fs = Math.max(Math.min(elem.width() / (compressor*10), parseFloat(settings.maxFontSize)), parseFloat(settings.minFontSize));
          elem.css('font-size', fs);
          setTimeout(function() { elem.trigger('fitText', fs); }, 0);
      };
            
      this.each(resizer);
    
      if (settings.watch) {
          $(window).on('resize.fittext orientationchange.fittext fittext', _.debounce(function() {
              var elements = (settings.live ? $(selector) : $this);
              if (options.filter) elements = elements.filter(options.filter);
              elements.each(resizer);
          }, 250));
      }
    
      return this;
  };
  
}));