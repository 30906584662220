
(function (factory) {
    if (typeof define === 'function' && define.amd) {
        define('jquery.fit',['jquery'], factory);
    } else {
        factory(jQuery);
    }
}(function($) {
  
  var csstransforms  = Modernizr && Modernizr.csstransforms;
  var csstransitions = Modernizr && Modernizr.csstransitions;

  function getMatrix(el) {
      var css = $(el).css(Modernizr.prefixed('transform'));
      var ctm = css.replace( /[a-z()]/gi, '' ).split( ',' );
      if (ctm.length < 6) { return [ 1, 0, 0, 1, 0, 0 ]; }
      for (var i = 0; i < 6; i++) { ctm[i] = parseFloat(ctm[i]); }
      return ctm;
  };

  var absolutePosition = function(animate, transform, element) {
      var $elem = $(element), $parent = $elem.parent();
      var parentPosition = $parent.css('position');
      if (parentPosition === 'static') $parent.css('position', 'relative');
      var elemPosition = $elem.css('position');
      if (elemPosition !== 'absolute') {
          $elem.css({ 
              position: 'absolute',
              top: 0, left: 0,
              float: 'none', minWidth: 'auto', minHeight: 'auto' 
          });
      }
    
      if (csstransforms) {
          var matrix = getMatrix(element);
          var x = matrix[4] + transform.tx;
          var y = matrix[5] + transform.ty;
          if (animate && _.isFunction($.fn.transition)) {
              var options = { queue: false, easing: 'snap', duration: 400 };
              $elem.transition($.extend(options, {
                  x: x, y: y,
                  width: transform.width, height: transform.height
              }));
          } else {
              $elem.width(transform.width).height(transform.height);
              $elem.css(Modernizr.prefixed('transform'), 'translate(' + x + 'px,' + y + 'px)');
          }
      } else {
          var position = $elem.position();
          var left = (position.left + (isNaN(transform.tx) ? 0 : transform.tx)) + 'px';
          var top = (position.top + (isNaN(transform.ty) ? 0 : transform.ty)) + 'px';
          if (animate) {
              $elem.stop().animate({ 
                  top: top, left: left, 
                  width: transform.width + 'px', height: transform.height + 'px' 
              }, { queue: false });
          } else {
              $elem.width(transform.width).height(transform.height);
              $elem.css({ top: top, left: left });
          }
      }
  };

  $.fn.fit = function(elem, options, callback) {
      if (elem === 'on' || elem === 'off' || elem === 'trigger') {
          this.each(function() {
              if (typeof($(this).data('fit')) === 'object') {
                  $(this).data('fit')[elem]();
              }
          });
          return this;
      }
    
      if ($.isFunction(options)) callback = options, options = {};
      options = $.extend({}, options);
      var container = $(this)[0];
      var elem = $(elem)[0];
    
      callback = callback || $.proxy(absolutePosition, null, options.animate);
    
      var transform = fit(elem, container, options, callback);
    
      if (options.watch) {
          $(this).data('fit', transform);
          $(this).on('fit.on', $.proxy(transform.on, transform, null));
          $(this).on('fit.off', $.proxy(transform.off, transform, null));
          $(this).on('fit.trigger', $.proxy(transform.trigger, transform, null));
      }
    
      return this;
  };

  // $('div').cover({ watch: true });
  // $('div').trigger('fit.off');
  // $('div').trigger('fit.on');
  // $('div').cover('off');
  // $('div').cover('on');

  $.fn.cover = function(elem, options) {
      if (elem === 'on' || elem === 'off' || elem === 'trigger') {
          return this.fit(elem);
      }
    
      var args = arguments;
      this.each(function() {
          var $this = $(this);
          if (elem instanceof HTMLElement || typeof(elem) === 'string') {
              var $elem = $this.children(elem).eq(0);
          } else if (elem instanceof jQuery) {
              var $elem = elem.eq(0);
          } else if (typeof(elem) === 'object' || args.length === 0) {
              var $elem = $this.children('img').eq(0);
              options = typeof(elem) === 'object' ? elem : {};
          }
        
          var defaults = { cover: true, hAlign: 'center', vAlign: 'center' };
          defaults.hAlign = $this.data('h-align') || defaults.hAlign;
          defaults.vAlign = $this.data('v-align') || defaults.vAlign;
                    
          options = $.extend(defaults, options || {});
        
          if (options.background && options.cover 
              && Modernizr && Modernizr.backgroundsize && $elem.is('img')) {
              $this.addClass('cover-bg');
              $this.css('background-image', 'url("' + $elem.attr('src') + '")');
              $this.css('background-position', options.hAlign + ' ' + options.vAlign);
              $this.css('background-size', 'cover');
              $elem.remove();
          } else if ($elem.is('*')) {
              if ($elem.is('img') && !$elem.hasClass('cover')) {
                  $elem.one('load', function() {
                      $this.fit($elem, options);
                  });
              }
              $this.fit($elem, options);
              $elem.addClass('cover');
          }
      });
    
      return this;
  };
  
}));