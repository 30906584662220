
(function($, window, undefined) {
    //is onprogress supported by browser?
    var hasOnProgress = ("onprogress" in $.ajaxSettings.xhr());

    //If not supported, do nothing
    if (!hasOnProgress) {
        return;
    }
    
    //patch ajax settings to call a progress callback
    var oldXHR = $.ajaxSettings.xhr;
    $.ajaxSettings.xhr = function() {
        var trigger = function(e) {
            var event = jQuery.Event('ajaxProgress');
            event.originalEvent = e;
            event.progress = e.lengthComputable ? (e.loaded / e.total) : 0;
            $.event.trigger(event, e);
        };
        
        var xhr = oldXHR();
        if (xhr instanceof window.XMLHttpRequest) {
            if (this.progress) xhr.addEventListener('progress', this.progress, false);
            xhr.addEventListener('progress', trigger, false);
        }
        
        if (xhr.upload) {
            if (this.progress) xhr.upload.addEventListener('progress', this.progress, false);
            xhr.addEventListener('progress', trigger, false);
        }
        
        return xhr;
    };
})(jQuery, window);

define("jquery.ajax-progress", function(){});
